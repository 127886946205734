#landingPage .testimonialsSection {
  position: relative;
}
.testimonialsSection > .background {
  position: absolute;
  width: 100%;
  height: 380px;
  background-color: #f5f7fa;
  /* top: 700px; */
  bottom: 0;
  z-index: -1;
}
.testimonialsSection .testimonialsContainer {
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
}
.testimonialsSection .titleContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.testimonialsSection .titleContainer > .image {
  width: 67%;
  height: 410px;
  object-fit: contain;
  box-shadow: 14px 12px 38px 0 rgba(60, 145, 204, 0.17);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  /* margin: 0 auto; */
}
.testimonialsSection .testimonialsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -90px;
  height: 380px;
}
.testimonialsSection .testimonialsContainer > .feature {
  width: 55%;
  margin-top: 130px;
  margin-left: 60px;
  padding-right: 40px;
}

.testimonialsContainer > .feature .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}
.testimonialsContainer > .feature .title .text {
  font-size: 22px;
  font-weight: 600;
  color: #4a4a4a;
}
.testimonialsContainer > .feature .actions.nav {
  width: 116px;
  display: flex;
  justify-content: space-between;
}
.testimonialsContainer > .feature .actions.nav.mobile {
  display: none;
}
.testimonialsContainer > .feature .actions.nav > .navBtn {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #4a4a4a;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.testimonialsContainer > .feature .subTitle {
  font-size: 16px;
  line-height: 1.63;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial {
  width: 480px;
  height: 430px;
  box-shadow: 0 15px 45px 0 rgba(60, 145, 204, 0.26);
  background-color: #ffffff;
  padding: 30px 30px 50px 50px;
  margin-top: -116px;
}
.testimonialsContainer > .testimonial .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.testimonialsContainer > .testimonial .details > img {
  width: 86px;
  height: 80px;
  opacity: 0.5;
}
.testimonialsContainer > .testimonial .author .name {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.21;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial .author .designation {
  color: #3c91cc;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.45;
  margin-top: 10px;
}
.testimonialsContainer > .testimonial .quote {
  margin-top: 20px;
  margin-bottom: 38px;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.63;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial > .action {
  width: 100%;
  border-radius: 24px;
  background-color: #3c91cc;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  display: block;
}

@media screen and (max-width: 1024px) {
  .testimonialsSection .titleContainer {
    padding: 0px;
    margin-left: 120px;
  }
  .testimonialsSection .testimonialsContainer {
    justify-content: space-between;
  }
  .testimonialsSection .testimonialsContainer > .feature {
    max-width: 300px;
  }
  .testimonialsContainer > .testimonial {
    width: 400px;
  }
  .testimonialsContainer > .testimonial > .action {
    width: 300px;
  }
  .testimonialsSection > .background {
    height: 480px;
    top: 740px;
  }
}
@media screen and (max-width: 767px) {
  .testimonialsSection .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonialsSection > .background {
    top: 180px;
    bottom: auto;
    height: 480px;
  }
  .testimonialsSection .titleContainer {
    flex-direction: column-reverse;
    max-width: 520px;
    margin: 0 auto !important;
    width: 100%;
  }
  .testimonialsSection .titleContainer > .image {
    width: 100%;
    height: 200px;
  }
  .testimonialsSection .testimonialsContainer {
    flex-direction: column;
    max-width: 520px;
    margin: 0 auto;
    height: auto;
  }
  .testimonialsSection .testimonialsContainer > .feature {
    width: 100%;
    margin: 20px 0 0;
    padding: 0;
    max-width: 100%;
  }
  .testimonialsSection .testimonialsContainer > .testimonial {
    margin-top: 20px;
    width: 100%;
    padding: 30px 20px;
  }
  .testimonialsContainer > .testimonial .details {
    align-self: flex-start !important;
  }
  .testimonialsContainer > .testimonial .details > img {
    margin-top: -14px;
    width: 52px;
    height: 50px;
  }
  .testimonialsContainer > .testimonial > .action {
    width: 100%;
  }
  .testimonialsContainer > .feature .actions.nav.mobile {
    display: flex;
    margin-top: 20px;
  }
  .testimonialsContainer > .feature .actions.nav.desk {
    display: none;
  }
}
