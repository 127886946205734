.featuresSection {
  margin-top: 280px;
  height: 680px;
  background-color: #f5f7fa;
  position: relative;
}
.featuresSection .section {
  position: absolute;
  left: calc(50vw - 590px);
  top: -220px;
  width: 1180px !important;
}
.featuresSection .section .titleContainer {
  margin: 0 auto;
  /* margin-left: -40px; */
}
.featuresSection .featuresList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 60px;
}

.featuresSection .featureCard {
  width: 360px;
  min-height: 280px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 50px 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.featuresSection .featureCard .imageContainer {
  display: flex;
  align-items: center;
}
.featuresSection .featureCard .featureImage {
  height: 50px;
  width: auto;
}
.featuresSection .featureCard .featureImage.fraud {
  width: 53px;
  height: 40px;
}
.featuresSection .featureCard .featureImage.accuracy {
  width: 48px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.beacon {
  width: 34px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.storage {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.reports {
  width: 51px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.tracking {
  width: 52px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.dashboard {
  width: 58px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.multi-team {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.report_workflow {
  width: 51px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.fraud_prevention {
  width: 53px;
  height: 40px;
}
.featuresSection .featureCard .featureImage.secure_storage {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.integrations {
  width: 50px;
  height: 50px;
}
.featuresSection .featureCard .featureTitle {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 30px;
  margin-bottom: 10px;
}

.featuresSection .featureCard .featureDescription {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
}
.enterprise .featuresSection {
  margin-top: 120px;
  background-color: #fff;
  height: 780px;
}
.enterprise .featuresSection .section {
  top: 0px;
}
@media screen and (max-width: 1200px) {
  .featuresSection {
    height: 1060px !important;
  }
  .enterprise .featuresSection {
    height: 1170px !important;
  }
  .featuresSection .section {
    left: calc(50vw - 410px) !important;
    width: 820px !important;
  }
}
@media screen and (max-width: 1024px) {
  .featuresSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .featuresSection .featuresList,
  .featuresSection .section .titleContainer {
    width: 100% !important;
  }
  .featuresSection .section .titleContainer {
    margin: 0px !important;
  }
  .featuresSection .section .titleContainer .sectionSubTitle {
    margin-left: 120px;
  }
  .enterprise .featuresSection {
    margin-top: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  .featuresSection {
    height: 1360px !important;
  }
  .enterprise .featuresSection {
    height: 1410px !important;
  }
  .featuresSection .section {
    left: calc(50vw - 180px) !important;
    padding: 0 30px !important;
    top: -180px;
    width: 360px !important;
  }
  .featuresSection .featureCard {
    width: 300px !important;
    min-height: 200px !important;
    padding: 40px 20px 32px !important;
    margin: 0px 0px 10px 0px !important;
  }
  .featuresSection .featureCard .firstPart {
    display: flex;
    align-items: center;
  }
  .featuresSection .featureCard .featureTitle {
    font-size: 18px;
    line-height: 1.33;
    margin: 0px 0px 0px 16px;
  }
  .featuresSection .featureCard .featureDescription {
    font-size: 14px;
    line-height: 1.43;
    margin-top: 18px;
  }
  .featuresSection .section .sectionTitle.mobile {
    margin-bottom: 0px !important;
  }
  .featuresSection .section .titleContainer {
    margin: 0px !important;
  }
  .featuresSection .section .titleContainer .sectionSubTitle {
    display: none;
  }
  .featuresSection .featuresList {
    margin-top: 20px !important;
    max-width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .featuresSection .section {
    padding: 0px 10px !important;
  }
  .featuresSection .section .titleContainer {
    padding-left: 20px !important;
  }
  .featuresSection .featureCard,
  .featuresSection .featureCard .featureDescription {
    height: auto;
  }

  .enterprise .featuresSection .section {
    position: inherit;
  }
}
