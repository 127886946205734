body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
body.dashboard {
  font-family: 'Lato', sans-serif;
}
* {
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 3px !important;
}
#navigation {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  /* border-bottom: 2px solid rgba(34, 36, 38, 0.15); */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 50px;
}

#page {
  position: absolute;
  width: 100%;
  top: 50px;
  height: calc(100% - 50px);
  overflow: auto;
  transition: all 0.4s ease;
}
#page.public {
  top: 0px;
  height: 100%;
  position: relative;
  padding: 0px;
  margin-top: 100px;
}

#page.listPageContainer {
  overflow: hidden;
  padding: 0px;
}
#page.notifBarShowing {
  top: 86px;
  height: calc(100% - 86px);
}
#page.isApp {
  top: 0;
  height: 100%;
}

#notifCenter {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
}
#notifCenter .animated {
  animation-duration: 0.5s;
  margin-bottom: 8px;
}
.ui.labeled.icon.button,
.ui.labeled.icon.buttons .button {
  width: auto;
}

#addressForm .ui.dropdown > .text,
#account .ui.dropdown > .text {
  max-width: calc(100% - 24px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#addressForm .searchDropdown.dropdown {
  border: 1px solid #979797;
}

#exportTripsWarning {
  width: 400px;
}
#exportTripsWarning .header {
  padding: 25px 40px 12px !important;
  font-size: 20px;
  color: #2185d0;
}
#exportTripsWarning .content {
  padding: 20px 40px !important;
  font-size: 14px;
  line-height: 1.43;
}
#exportTripsWarning .content .bold {
  font-weight: 600;
}
#exportTripsWarning .actions {
  padding: 14px 40px !important;
}

/* smooch iframe */
#web-messenger-container {
  bottom: 20px;
  right: 14px;
}

#errorTooltip {
  background-color: #db2828;
  color: #fff;
}
#errorTooltip.ui.popup:before {
  background-color: #db2828;
  box-shadow: 1px 1px 0 0 #db2828;
}

.unit.currency > .symbol {
  margin-right: 2px;
}

@media screen and (max-width: 991px) {
  #page {
    padding: 0px 20px;
  }
}
@media screen and (max-width: 768px) {
  #page::-webkit-scrollbar {
    width: 3px;
  }
}
@media screen and (max-width: 368px) {
  #page {
    padding: 0px 10px;
  }
}
