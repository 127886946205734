#mobile-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 20px; */
  height: 50px;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  background-color: #ffffff;
}
#mobile-menu .mobileMenuLeft {
  display: flex;
  align-items: center;
}
#mobile-menu .mobileMenuRight {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
}

#mobile-menu i.icon {
  margin: 0;
  text-align: right;
}

/* Sidebar START */
.mobileSidebar,
.dimmedSidebarBg {
  height: calc(100vh - 50px);
  top: 50px;
  left: 0px;
  position: fixed;
}
.dimmedSidebarBg {
  width: 100vw;
  z-index: 99;
  filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
}
.dimmedSidebarBg.visible {
  display: block;
}
.mobileSidebar {
  width: 85vw;
  position: fixed; /* Stay in place */
  z-index: 101; /* Stay on top */
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  margin-left: -100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobileSidebar.open {
  margin-left: 0;
}

.mobileSidebar .footer {
  padding: 20px;
  height: auto;
}
.mobileSidebar .footer .langItem {
  margin-right: 16px;
}
.mobileSidebar .footer .langItem:last-child {
  margin-right: 0px;
}
.mobileSidebar .footer .langItem.selected {
  background-color: transparent;
  text-decoration: underline;
  color: #24b646;
  font-weight: bold;
}
.sidebarMenuHeaderItem {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(222, 222, 222, 0.4);
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.sidebarMenuItem {
  border-bottom: 1px solid rgba(222, 222, 222, 0.4);
  height: 52px;
  /* height: calc((100vh - 50px) / 9); */
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.25;
  cursor: pointer;
}
.sidebarMenuItem:last-child {
  border-bottom: none;
}
.sidebarMenuItem[item-key='signout'] {
  color: #db2828;
}
.sidebarMenuItem.active {
  font-weight: bold;
}
.sidebarMenuItem > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100%;
  height: 100%;
}
.sidebarLabel {
  height: 20px;
  border-radius: 4px;
  background-color: #db2828;
  padding: 0 7px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
/* Sidebar END */

/* Teambar START */
.mobileTeamBar {
  width: 100vw;
  position: fixed; /* Stay in place */
  z-index: 99; /* Stay on top */
  top: 50px; /* Stay at the top */
  left: 0;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: -100vh;
}
.mobileTeamBar.open {
  margin-top: 0;
}
.teamMenuItem {
  border-bottom: 1px solid rgba(222, 222, 222, 0.4);
  height: 52px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.25;
  cursor: pointer;
}
.teamMenuItem:last-child {
  border-bottom: none;
}
.teamMenuItem.active {
  background-color: rgba(222, 222, 222, 0.4);
  font-weight: bold;
}
.dimmedTeambarBg {
  height: calc(100vh - 50px);
  width: 100vw;
  position: fixed;
  z-index: 98;
  top: 50px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}
.dimmedTeambarBg.visible {
  display: block;
}
#landingHeader .mobileSidebar,
#landingHeader .dimmedSidebarBg,
#landingHeader .dimmedTeambarBg {
  height: calc(100vh - 72px);
  top: 72px;
}

/* Teambar END */
