#landingPage .howItWorksSection {
  display: flex;
  flex-direction: column;
  padding-top: 138px;
  padding-bottom: 240px;
}
.howItWorksSection .worksContainer {
  margin-top: 98px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.howItWorksSection .worksContainer .separator {
  width: 110px;
  height: 1px;
  opacity: 0.4;
  border: 2px dashed #889ba7;
  margin-top: 30px;
}
.howItWorksSection .worksContainer .workPartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howItWorksSection .worksContainer .workPartWrapper .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howItWorksSection .worksContainer .workPartWrapper .image {
  width: auto;
  height: 70px;
}
.howItWorksSection .worksContainer .workPartWrapper .title {
  font-size: 22px;
  font-weight: 600;
  color: #4a4a4a;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.36;
}
.howItWorksSection .worksContainer .workPartWrapper .subTitle {
  max-width: 340px;
  font-size: 16px;
  line-height: 1.63;
  color: #666666;
  text-align: center;
}

/* Enterprise START */

.enterprise .howItWorksSection {
  background-color: #f5f7fa !important;
  padding: 100px 0px 0px !important;
}
.enterprise .howItWorksSection .section {
  width: 1400px;
}
.enterprise .howItWorksSection .section .titleContainer {
  padding-left: 36px;
}
.enterprise .howItWorksSection .progressListContainer {
  display: none;
}
.enterprise .howItWorksSection .worksContainer {
  margin-top: 50px;
  display: block;
}
.enterprise .howItWorksSection .stepsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.enterprise .howItWorksSection .stepWrapper {
  width: 280px;
  height: 230px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 44px;
  border-radius: 10px;
  position: relative;
}
.enterprise .howItWorksSection .stepWrapper:last-child {
  margin-right: 0px !important;
}
.enterprise .howItWorksSection .stepsList .stepWrapper.active {
  box-shadow: 0 8px 6px 0 rgba(36, 182, 70, 0.1);
  background-color: #ffffff;
}
.enterprise .howItWorksSection .stepWrapper .number {
  position: absolute;
  font-size: 82px;
  font-weight: 900;
  opacity: 0.2;
  line-height: 0.85;
  color: #3c91cc;
  transition: all 0.3s ease;
  left: -20px;
  top: 10px;
  width: 60px;
  display: flex;
  justify-content: center;
}
.enterprise .howItWorksSection .stepWrapper.active .number {
  color: #24b646;
  opacity: 0.4;
}
.enterprise .howItWorksSection .stepsList .step {
  padding: 20px;
}
.enterprise .howItWorksSection .stepsList .step .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  color: #333333;
}
.enterprise .howItWorksSection .stepsList .step .desc {
  margin-top: 14px;
  font-size: 15px;
  line-height: 1.6;
  color: #4a4a4a;
}
.enterprise .howItWorksSection .imageContainer {
  margin-top: 22px;
}
.enterprise .howItWorksSection .imageContainer > div {
  width: 828px;
  height: 479px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px auto -60px;
}

/* Enterprise END */

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .howItWorksSection .worksContainer .workPartWrapper .title {
    font-size: 20px;
  }
  .enterprise .howItWorksSection .stepsList .step .title {
    font-size: 16px;
  }
  .enterprise .howItWorksSection .stepsList .step .desc {
    line-height: 1.43;
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  #landingPage .howItWorksSection {
    padding: 80px 0px 130px;
  }
  .howItWorksSection .section {
    /* padding: 0px 40px !important; */
  }
  .howItWorksSection .worksContainer {
    margin-top: 60px;
  }
  .howItWorksSection .worksContainer .separator {
    display: none;
  }
  .howItWorksSection .worksContainer .workPartWrapper {
    /* flex-direction: row; */
    /* align-items: flex-start; */
    /* margin-bottom: 60px; */
    /* max-width: 220px; */
  }
  .howItWorksSection .worksContainer .workPartWrapper:last-child {
    /* margin-bottom: 0px; */
  }
  .howItWorksSection .worksContainer .workPartWrapper .header {
    /* flex-direction: row; */
  }
  .howItWorksSection .worksContainer .workPartWrapper .image {
    height: 50px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .title {
    margin: 30px 0px 10px;
    text-align: center;
    max-width: 160px;
    font-size: 20px;
    line-height: 1.4;
  }
  .howItWorksSection .worksContainer .workPartWrapper .subTitle {
    max-width: 220px;
    font-size: 14px;
    line-height: 1.43;
  }
  .enterprise .howItWorksSection .section {
    padding: 0 !important;
  }
  .enterprise .howItWorksSection .stepsList {
    width: 100%;
    overflow: hidden;
  }
  .enterprise .howItWorksSection .stepsList .step {
    padding: 20px 10px 20px 20px;
  }
  .enterprise .howItWorksSlider {
    /* width: 920px; */
    width: 100%;
    /* width: 380px; */
    height: 200px;
  }
  .enterprise .howItWorksSlider .sliderItem {
  }
  .enterprise .howItWorksSection .stepWrapper {
    /* width: auto !important; */
    width: 260px;
    height: 170px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    box-shadow: 0 8px 6px 0 rgba(36, 182, 70, 0.1);
    background-color: #ffffff;
  }
  .enterprise .howItWorksSection .stepWrapper:last-child {
    margin-right: 0px !important;
  }
  .enterprise .howItWorksSection .stepWrapper:first-child {
    margin-left: 0px !important;
  }
  .enterprise .howItWorksSection .stepWrapper .number {
    font-size: 62px;
  }
  .enterprise .howItWorksSection .stepsList .step .title {
    font-size: 16px;
  }
  .enterprise .howItWorksSection .stepsList .step .desc {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.43;
  }
  .enterprise .howItWorksSection .imageContainer {
    margin-top: 18px;
  }
  .enterprise .howItWorksSection .imageContainer > div {
    width: 314px;
    height: 362px;
  }
}

@media screen and (max-width: 767px) {
  #landingPage .howItWorksSection {
    padding: 60px 0px 80px;
  }
  .howItWorksSection .section {
    margin-left: auto !important;
  }
  .howItWorksSection .section .titleContainer .sectionSubTitle {
    display: none;
  }
  .howItWorksSection .worksContainer {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
  }
  .howItWorksSection .worksContainer .workPartWrapper {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
  }
  .howItWorksSection .worksContainer .workPartWrapper:last-child {
    margin-bottom: 0px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .header {
    flex-direction: row;
  }
  .howItWorksSection .worksContainer .workPartWrapper .image {
    height: 36px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    max-width: 160px;
    text-align: left;
    margin: 0px;
    margin-left: 20px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .subTitle {
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.43;
    max-width: 260px;
    text-align: left;
  }

  .home .howItWorksSection .section .titleContainer {
    padding-left: 30px;
  }

  .enterprise .howItWorksSection .stepWrapper {
    height: 190px !important;
  }
  #landingPage .howItWorksSection .section {
    padding: 0 !important;
  }
  .howItWorksSection .section .titleContainer {
    padding: 0px 30px;
  }
  .enterprise .howItWorksSection .worksContainer {
    margin-top: 20px;
  }
  .enterprise .howItWorksSection .imageContainer {
    margin-top: 10px;
  }
  .enterprise .howItWorksSection .imageContainer > div {
    width: 244px;
    height: 281px;
  }
  .enterprise .howItWorksSection .imageContainer > div.slide4 {
    width: 100vw;
  }
  .enterprise .howItWorksSection .progressListContainer {
    display: flex !important;
    margin-top: 30px;
    justify-content: center;
  }
  .enterprise .howItWorksSection .progressListContainer .progressBar {
    width: 54px;
    height: 6px;
    background-color: rgba(36, 182, 70, 0.2);
    position: relative;
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  .enterprise
    .howItWorksSection
    .progressListContainer
    .progressBar:last-child {
    margin-right: 0px;
  }
  .enterprise .howItWorksSection .progressListContainer .progressBar.complete {
    background-color: #24b646;
  }
  .enterprise
    .howItWorksSection
    .progressListContainer
    .progressBar.active
    .progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 100%;
    background-color: #24b646;
  }

  .enterprise .howItWorksSection .seeInActionContainer .showVideoAction {
    font-size: 16px !important;
  }
}
