#landingHeader {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
#landingHeader .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 10px !important;
}
#landingHeader .left {
  display: flex;
  align-items: center;
}
#landingHeader .left > .menuItem {
  padding: 0px 20px;
}
#landingHeader .left .menuItem {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #333333;
  height: 40px;
  display: flex;
  align-items: center;
}
#landingHeader .left .menuItem:not(.logo):hover,
#landingHeader .left .solutionsDropdown:hover,
#landingHeader .right .languageDropdown:hover {
  background-color: #f5f7fa;
  transition: 0.3s all ease;
}
#landingHeader .left .menuItem.logo {
  padding: 0;
  padding-right: 20px;
}
#landingHeader .left .image,
#landingHeader.mobile .homeLogo img {
  height: 36px;
}
#landingHeader .left .solutionsDropdown {
  display: inline-flex;
  align-items: center;
  padding: 0px 20px;
}
#landingHeader .left .solutionsDropdown .item {
  padding: 0px !important;
}
#landingHeader .left .solutionsDropdown .item > a {
  color: #4a4a4a;
  text-decoration: none !important;
  font-size: 14px;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  display: block;
}
#landingHeader i.icon.chevron {
  margin-left: 8px;
  margin-right: 0;
}
#landingHeader .right {
  display: flex;
  align-items: center;
}
#landingHeader .right .languageDropdown {
  margin-right: 10px;
  padding: 0px 20px;
  height: 40px;
  display: flex;
  align-items: center;
}
#landingHeader .right .languageDropdown i.icon.chevron.down:before {
  color: #24b646;
}
#landingHeader .right .languageDropdown .text {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #24b646;
}
#landingHeader .right .signInBtn,
#landingHeader.mobile .signInBtn {
  padding: 10px 25px;
  text-align: center;
  border-radius: 18px;
  background-color: #24b646;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  border: 0;
  cursor: pointer;
}
#landingHeader .right .dashboardBtn {
  color: #24b646;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
}
#landingHeader.mobile .menuIcon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: right;
}
#landingPage {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}
#landingPage .section {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
}
#landingPage .section.tablet {
  display: none !important;
}

.section .sectionTitle {
  font-size: 44px;
  font-weight: bold;
  line-height: 1.27;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section .sectionTitle.mobile {
  display: none !important;
}
.section .sectionTitle .secondLine {
  display: flex;
  align-items: center;
}
.section .sectionTitle .secondLine .dash {
  width: 100px;
  height: 6px;
  background-color: #4a4a4a;
  margin-right: 20px;
}
.section .titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.section .titleContainer .sectionSubTitle {
  font-size: 22px;
  color: #4a4a4a;
  line-height: 1.45;
  max-width: 520px;
}

#landingFooter {
  padding: 80px 0px 100px;
}
#landingFooter .section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1280px;
  margin: 0 auto;
}
#landingFooter .productDetails .product {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
#landingFooter .productDetails .product > img {
  width: 48px;
  height: 48px;
}
#landingFooter .productDetails .product > .name {
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #24b646;
}
#landingFooter .productDetails .warmGrey {
  font-size: 14px;
  color: #9b9b9b;
  line-height: 1.57;
}
#landingFooter .links {
  display: flex;
}
#landingFooter .links .container {
  display: flex;
  flex-direction: column;
  width: 160px;
}
#landingFooter .links .container .link {
  margin-bottom: 16px;
  font-size: 14px;
  color: #9b9b9b !important;
  text-decoration: none !important;
  font-weight: 500;
  cursor: pointer;
}
#landingFooter .links .container .link:last-child {
  margin-bottom: 0px;
}
#landingFooter .links .container > .langSelector {
  color: #9b9b9b;
}
#landingFooter .links .container > .langSelector .icon {
  margin-left: 6px;
}
#landingFooter .links .container > .langSelector .item {
  color: #9b9b9b;
}
#landingFooter .socialLinks {
  display: flex;
}
#landingFooter .socialLinks > a {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
#landingFooter .socialLinks > a:last-child {
  margin-right: 0px;
}
#landingFooter .socialLinks > a > img {
  width: 100%;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  #landingPage .section,
  #landingFooter .section {
    padding: 0px 20px;
  }
  #landingHeader .section {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  #landingPage .section {
    padding: 0px 40px;
  }
  #landingHeader .section {
    padding: 20px 40px !important;
  }
  #landingFooter .section {
    padding: 0px 40px;
  }
  .section .sectionTitle {
    display: none !important;
  }
  .section .sectionTitle.mobile {
    display: flex !important;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .section .sectionTitle.mobile .dash {
    width: 80px;
    height: 6px;
    background-color: #4a4a4a;
    margin-right: 40px;
  }
  .section .titleContainer .sectionSubTitle {
    margin-left: 120px;
    font-size: 20px;
    line-height: 1.5;
  }
  .section .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .questionsSection .questionsContainer {
    padding: 60px 30px !important;
  }
  .questionsSection .questionsContainer > .left {
    max-width: 340px;
  }
  .questionsSection .questionsContainer .right {
    width: 160px !important;
  }
  .questionsSection .questionsContainer .center .image {
    width: 172px;
    height: auto;
  }
  .questionsSection .sectionTitle.mobile {
    font-size: 40px;
    margin-left: 0px;
  }

  #landingFooter {
    padding: 60px 0px 40px;
  }
  #landingFooter .socialLinks {
    margin-top: 10px;
  }
  .introSection .details {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 767px) {
  #landingPage .section,
  #landingHeader .section,
  #landingFooter .section {
    padding: 0px 30px !important;
  }
  #landingHeader .section {
    padding: 16px 30px !important;
  }
  #landingHeader.mobile .section {
    padding: 16px 20px 16px 30px !important;
  }
  #landingHeader .left .image {
    width: 22px;
  }
  #landingHeader .right .signInBtn {
    padding: 8px 16px;
  }
  .section .sectionTitle.mobile {
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-start;
  }
  .section .sectionTitle.mobile .dash {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .section .sectionTitle.mobile .title {
    font-size: 30px;
    line-height: 1.33;
  }
  .section .titleContainer {
    margin-left: 0px !important;
  }
  .section .titleContainer .sectionSubTitle {
    font-size: 18px;
    line-height: 1.56;
    margin-left: 0px;
  }

  #landingHeader .mobileHeaderMenu a {
    color: #4a4a4a;
    text-decoration: none !important;
  }
  #landingHeader .mobileHeaderMenu .item {
    padding: 12px 16px !important;
  }
  #landingHeader .mobileHeaderMenu .item .solutionsDropdown {
    padding: 0px;
  }
  #landingHeader .mobileHeaderMenu .item .solutionsDropdown .menuItem {
    font-weight: normal;
  }

  #landingFooter {
    padding: 60px 0px 20px;
    border-top: 1px solid #dedede;
  }
  #landingFooter .section {
    flex-direction: column;
  }
  #landingFooter .links {
    margin-top: 30px;
  }

  #landingFooter .firstPart {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  #landingFooter .productDetails .product > img {
    width: 36px;
    height: 36px;
  }
  #landingFooter .productDetails .product > .name {
    margin-left: 14px;
    font-size: 24px;
  }
  #landingFooter .productDetails .warmGrey {
    font-size: 12px;
  }
  #landingFooter .socialLinks {
    margin-top: 4px;
  }
  #landingFooter .socialLinks > a {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
  #landingFooter .socialLinks > a:last-child {
    margin-right: 0px;
  }

  .pro .questionsSection {
    display: none;
  }
  .questionsSection .section {
    padding: 0 !important;
  }
  .questionsSection .questionsContainer {
    flex-direction: column;
    height: 400px !important;
  }
  .questionsSection .quesitonsSectionsContent {
    flex-direction: row-reverse;
    width: 100% !important;
    max-width: 340px;
  }
}
@media screen and (max-width: 350px) {
  #landingHeader .section {
    padding: 16px !important;
  }
  #landingHeader .right .signInBtn {
    font-size: 12px;
  }
}

.introSection {
  display: flex;
  margin: 80px auto 0;
  min-height: 727px;
  width: 100%;
  max-width: 1280px;
  padding-left: 80px;
  position: relative;
}
.introSection .left {
  width: 56%;
  display: flex;
  position: relative;
  z-index: 10;
}
.pro .introSection .left {
  width: 50%;
}
.enterprise .introSection .left {
  width: 50%;
}
.introSection .right {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1331px;
  display: flex;
  margin-bottom: 72px;
  transform: translateY(-50px);
}
.introSection .details {
  margin-top: 90px;
  padding-right: 20px;
}
.introSection .details .appName {
  font-size: 30px;
  color: #24b646;
  font-weight: bold;
  line-height: 1.33;
}
.introSection .details .title {
  font-size: 54px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 30px;
  line-height: 1.22;
  padding-right: 16px;
}
.introSection .details .subTitle {
  color: #666666;
  font-size: 20px;
  line-height: 1.6;
  max-width: 430px;
}
.introSection .details .actions {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
}
.introSection .actions .action {
  margin: 5px 20px 5px 0;
}
.introSection .actions .action:last-child {
  margin-right: 0;
}
.introSection .actions .action.appStoreLink {
  width: 150px;
  height: 70px;
}
.introSection .actions .action.playStoreLink {
  height: 66px;
  width: 171px;
  margin-top: 7px;
}
.introSection .actions .action > img {
  width: 100%;
  height: 100%;
}
.introSection .right .dashboardScreen {
  width: 100%;
  background-image: url('/images/landing/dashboardScreenshot.png');
  background-repeat: no-repeat;
  background-size: 810px 549px;
  background-position: bottom left;
}
.introSection .right .appScreen {
  width: 237px;
  height: 414px;
  object-fit: contain;
  background-image: url('/images/landing/appScreenshot.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
/* .introVideo {
  width: 1280px;
  height: 640px;
} */

/* .react-player {
  height: 640px;
} */

/* Landing Pro START */
.pro .introSection .details {
  padding-bottom: 130px;
}
.pro .introSection .right .dashboardScreen {
  background-image: url('/images/landing/car.png');
  /* background-size: 1624px 557px; */
  background-size: 1331px 570px;
  display: flex;
  /* background-position-x: -40px;
  background-position-y: 140px; */
}
.pro .introSection .right .appScreen {
  width: 320px;
  height: 560px;
  bottom: 10px;
  /* left: 250px; */
}
.pro .introSection .right .playButton {
  background-image: url('/images/landing/btn_play.svg');
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  object-fit: contain;
  width: 120px;
  height: 90px;
}

.pro .introSection .details .actions .upgradeBtn,
.enterprise .introSection .details .actions .upgradeBtn {
  width: 300px;
  height: 60px;
  border-radius: 30px;
  background-color: #24b646;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  outline: none;
  line-height: 60px;
  display: block;
}

/* Landing Pro END */

/* Landing Enterprise START */

.enterprise .introSection .right .dashboardScreen {
  background-image: url('/images/landing/dashboardScreenshot_reports.png');
}
.enterprise .introSection .details .actions {
  flex-direction: column;
}
.enterprise .introSection .details .actions .showVideoAction {
  color: #00b932 !important;
  font-size: 18px !important;
  font-weight: 500;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}
.enterprise .introSection .details .actions .showVideoAction > * {
  display: inline-block;
  vertical-align: middle;
}
.enterprise .introSection .details .actions .showVideoAction > .image {
  width: 40px;
  margin-right: 12px;
}
#seeInActionModal {
  margin-top: 0px !important;
}
#seeInActionModal .content {
  background-color: #000;
}

/* Landing Enterprise END */

@media screen and (max-width: 1280px) {
  .introSection .details .title {
    font-size: 44px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #333333;
  }

  .pro .introSection .right .playButton {
    margin: auto auto auto 30%;
  }
}
@media screen and (max-width: 1024px) {
  .introSection {
    min-height: 540px;
    padding: 0 40px;
  }
  .introSection .left {
    width: 55%;
    display: flex;
    position: relative;
  }
  .introSection .right {
    left: 55%;
    width: 1000px;
    display: flex;
  }
  .introSection .details .title {
    font-size: 38px;
  }
  .introSection .right .dashboardScreen {
    background-size: 583px 395px;
    /* background-size: 672px 455px; */
  }
  .introSection .right .appScreen {
    width: 171px;
    height: 298px;
    left: 10px;
  }

  /* Landing Pro START */

  .pro .introSection .right .dashboardScreen {
    background-image: url('/images/landing/car.png');
    /* background-size: 1624px 557px; */
    background-size: 887px 380px;
    /* background-position: -70px 115px; */
  }
  .pro .introSection .right .appScreen {
    width: 229px;
    height: 400px;
    bottom: 10px;
    /* left: 72px; */
  }

  .pro .introSection .right .playButton {
    margin: auto auto auto 23%;
  }

  /* Landing Pro END */
}
@media screen and (max-width: 767px) {
  .introSection {
    flex-direction: column;
    padding: 0;
    margin-top: 72px;
  }
  .introSection .left {
    width: 100% !important;
    padding: 0 30px;
  }
  .introSection .right {
    position: relative;
    left: 0;
    height: 230px;
    width: 331px;
    margin: 40px auto 0;
    transform: none !important;
  }
  .introSection .details {
    margin: 30px auto 0 !important;
  }
  .introSection .details .appName {
    font-size: 20px;
    color: #24b646;
    font-weight: bold;
  }
  .introSection .details .title {
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .introSection .details .subTitle {
    color: #666666;
    font-size: 18px;
    line-height: 1.44;
  }
  .introSection .details .actions {
    margin-top: 40px;
    justify-content: center;
  }
  .introSection .right .dashboardScreen {
    width: 100%;
    background-size: 331px 221px;
  }
  .introSection .right .appScreen {
    width: 95px;
    height: 167px;
    left: 20px;
  }

  /* Landing Pro START */
  .pro .introSection .details {
    padding-bottom: 0;
  }
  .pro .introSection .right {
    position: relative;
    left: 0;
    height: 230px;
    /* margin-top: 40px; */
    width: 100% !important;
    margin: 0px auto;
    height: 400px;
  }
  .pro .introSection .right .dashboardScreen {
    /* background-size: 140vw 289px; */
    background-size: 762px 309px;
    background-position: 90px;
    width: 110vw;
    height: 310px;
    transform: translateY(140px);
  }
  .pro .introSection .right .appScreen {
    width: 200px;
    height: 354px;
    bottom: 0px;
    left: calc(20px);
  }

  .pro .introSection .right .playButton {
    margin: auto 10% auto auto;
  }

  .pro .introSection .details .actions .upgradeBtn,
  .enterprise .introSection .details .actions .upgradeBtn {
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #24b646;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    outline: none;
    line-height: 60px;
    margin: 0;
  }

  /* Landing Pro END */
}

@media screen and (max-width: 525px) {
  .pro .introSection .right .dashboardScreen {
    background-position: -40px;
  }
}

.statsSection {
  background-color: #f5f7fa;
  height: 380px;
  margin-top: -50px;
}
.statsSection .section {
  display: flex;
  justify-content: space-around;
  height: 100%;
}
.statsSection .distanceStats,
.statsSection .ratingStats,
.statsSection .awardsStats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statsSection .awardImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: bottom;
  grid-gap: 24px;
  gap: 24px;
}
.statsSection .distanceStats .distance.unit {
  line-height: 1.09;
  color: #24b646;
  font-size: 24px;
  font-weight: 800;
}
.statsSection .distanceStats .distance.unit .quantity {
  font-size: 44px;
}
.statsSection .distanceStats .distance.unit .unit {
  margin-left: 6px;
}
.statsSection .distanceStats .text {
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  line-height: 1.36;
}
.statsSection .ratingStats {
  width: 460px;
}
.statsSection .ratingStats .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.statsSection .ratingStats .rating .ratingText {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
}
.statsSection .ratingStats .ratingStars .star {
  display: inline-block;
  background: url('/images/star.png') center no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.statsSection .ratingStats .ratingStars .star:last-child {
  margin-right: 0px;
}
.statsSection .ratingStats .ratingStars .star.half {
  background-image: url('/images/half-star.png');
}
.statsSection .ratingStats .testimonial .box {
  width: 100%;
  padding: 20px 30px;
  background-color: #24b646;
  opacity: 0.8;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.56;
  border-radius: 8px;
  position: relative;
}
.statsSection .ratingStats .testimonial .box.pointed::after {
  content: '';
  width: 0;
  height: 0;
  border-bottom: 25px solid #24b646;
  border-right: 25px solid transparent;
  position: absolute;
  bottom: -8px;
  right: 30px;
  transform: rotate(-30deg);
  opacity: 0.9;
}
.statsSection .ratingStats .testimonial .author {
  font-size: 14px;
  color: #4a4a4a;
  text-align: right;
  margin-right: 70px;
  margin-top: 4px;
}
.statsSection .awardsStats .awardImg {
  width: 82px;
  height: 79px;
}
.statsSection .awardsStats .text {
  color: #4a4a4a;
  font-size: 22px;
  line-height: 1.36;
  text-align: center;
  margin-top: 14px;
  max-width: 280px;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 38px;
  }
  .statsSection .ratingStats {
    width: 400px;
  }
  .statsSection .ratingStats .testimonial .box {
    padding: 20px 16px;
  }
  .statsSection .awardsStats .text,
  .statsSection .distanceStats .text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .statsSection {
    height: 530px;
    margin-top: 45px;
  }
  .statsSection .section {
    display: none !important;
  }
  #landingPage .statsSection .section.mobile {
    display: flex !important;
    flex-direction: column;
  }
  .statsSection .section.mobile .firstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .statsSection .section.mobile .secondRow {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .statsSection {
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
    margin-top: -20px;
  }
  .statsSection .section.mobile .firstRow {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .statsSection .distanceStats {
    margin-bottom: 40px;
  }
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 34px;
  }
  .statsSection .distanceStats .distance.unit .unit {
    font-size: 18px;
  }
  .statsSection .distanceStats .text {
    font-size: 18px;
    margin-top: 10px;
  }

  .statsSection .awardsStats .text {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    margin-top: 10px;
  }

  .statsSection .ratingStats .rating {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .statsSection .ratingStats .rating .ratingStars {
    margin-bottom: 12px;
  }
  .statsSection .ratingStats .rating .ratingStars .star {
    height: 20px;
    width: 20px;
    background-size: cover;
  }
  .statsSection .ratingStats .rating .ratingText {
    margin-left: 0;
  }
  .statsSection .ratingStats .testimonial .box {
    font-size: 14px;
    font-weight: 300;
    height: 145px;
  }
  .statsSection .ratingStats .testimonial .box > div {
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 350px) {
  .statsSection .distanceStats .distance.unit {
    font-size: 18px !important;
  }
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 34px !important;
  }
}

#landingPage .howItWorksSection {
  display: flex;
  flex-direction: column;
  padding-top: 138px;
  padding-bottom: 240px;
}
.howItWorksSection .worksContainer {
  margin-top: 98px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.howItWorksSection .worksContainer .separator {
  width: 110px;
  height: 1px;
  opacity: 0.4;
  border: 2px dashed #889ba7;
  margin-top: 30px;
}
.howItWorksSection .worksContainer .workPartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howItWorksSection .worksContainer .workPartWrapper .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howItWorksSection .worksContainer .workPartWrapper .image {
  width: auto;
  height: 70px;
}
.howItWorksSection .worksContainer .workPartWrapper .title {
  font-size: 22px;
  font-weight: 600;
  color: #4a4a4a;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.36;
}
.howItWorksSection .worksContainer .workPartWrapper .subTitle {
  max-width: 340px;
  font-size: 16px;
  line-height: 1.63;
  color: #666666;
  text-align: center;
}

/* Enterprise START */

.enterprise .howItWorksSection {
  background-color: #f5f7fa !important;
  padding: 100px 0px 0px !important;
}
.enterprise .howItWorksSection .section {
  width: 1400px;
}
.enterprise .howItWorksSection .section .titleContainer {
  padding-left: 36px;
}
.enterprise .howItWorksSection .progressListContainer {
  display: none;
}
.enterprise .howItWorksSection .worksContainer {
  margin-top: 50px;
  display: block;
}
.enterprise .howItWorksSection .stepsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.enterprise .howItWorksSection .stepWrapper {
  width: 280px;
  height: 230px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 44px;
  border-radius: 10px;
  position: relative;
}
.enterprise .howItWorksSection .stepWrapper:last-child {
  margin-right: 0px !important;
}
.enterprise .howItWorksSection .stepsList .stepWrapper.active {
  box-shadow: 0 8px 6px 0 rgba(36, 182, 70, 0.1);
  background-color: #ffffff;
}
.enterprise .howItWorksSection .stepWrapper .number {
  position: absolute;
  font-size: 82px;
  font-weight: 900;
  opacity: 0.2;
  line-height: 0.85;
  color: #3c91cc;
  transition: all 0.3s ease;
  left: -20px;
  top: 10px;
  width: 60px;
  display: flex;
  justify-content: center;
}
.enterprise .howItWorksSection .stepWrapper.active .number {
  color: #24b646;
  opacity: 0.4;
}
.enterprise .howItWorksSection .stepsList .step {
  padding: 20px;
}
.enterprise .howItWorksSection .stepsList .step .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  color: #333333;
}
.enterprise .howItWorksSection .stepsList .step .desc {
  margin-top: 14px;
  font-size: 15px;
  line-height: 1.6;
  color: #4a4a4a;
}
.enterprise .howItWorksSection .imageContainer {
  margin-top: 22px;
}
.enterprise .howItWorksSection .imageContainer > div {
  width: 828px;
  height: 479px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px auto -60px;
}

/* Enterprise END */

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .howItWorksSection .worksContainer .workPartWrapper .title {
    font-size: 20px;
  }
  .enterprise .howItWorksSection .stepsList .step .title {
    font-size: 16px;
  }
  .enterprise .howItWorksSection .stepsList .step .desc {
    line-height: 1.43;
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  #landingPage .howItWorksSection {
    padding: 80px 0px 130px;
  }
  .howItWorksSection .section {
    /* padding: 0px 40px !important; */
  }
  .howItWorksSection .worksContainer {
    margin-top: 60px;
  }
  .howItWorksSection .worksContainer .separator {
    display: none;
  }
  .howItWorksSection .worksContainer .workPartWrapper {
    /* flex-direction: row; */
    /* align-items: flex-start; */
    /* margin-bottom: 60px; */
    /* max-width: 220px; */
  }
  .howItWorksSection .worksContainer .workPartWrapper:last-child {
    /* margin-bottom: 0px; */
  }
  .howItWorksSection .worksContainer .workPartWrapper .header {
    /* flex-direction: row; */
  }
  .howItWorksSection .worksContainer .workPartWrapper .image {
    height: 50px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .title {
    margin: 30px 0px 10px;
    text-align: center;
    max-width: 160px;
    font-size: 20px;
    line-height: 1.4;
  }
  .howItWorksSection .worksContainer .workPartWrapper .subTitle {
    max-width: 220px;
    font-size: 14px;
    line-height: 1.43;
  }
  .enterprise .howItWorksSection .section {
    padding: 0 !important;
  }
  .enterprise .howItWorksSection .stepsList {
    width: 100%;
    overflow: hidden;
  }
  .enterprise .howItWorksSection .stepsList .step {
    padding: 20px 10px 20px 20px;
  }
  .enterprise .howItWorksSlider {
    /* width: 920px; */
    width: 100%;
    /* width: 380px; */
    height: 200px;
  }
  .enterprise .howItWorksSlider .sliderItem {
  }
  .enterprise .howItWorksSection .stepWrapper {
    /* width: auto !important; */
    width: 260px;
    height: 170px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    box-shadow: 0 8px 6px 0 rgba(36, 182, 70, 0.1);
    background-color: #ffffff;
  }
  .enterprise .howItWorksSection .stepWrapper:last-child {
    margin-right: 0px !important;
  }
  .enterprise .howItWorksSection .stepWrapper:first-child {
    margin-left: 0px !important;
  }
  .enterprise .howItWorksSection .stepWrapper .number {
    font-size: 62px;
  }
  .enterprise .howItWorksSection .stepsList .step .title {
    font-size: 16px;
  }
  .enterprise .howItWorksSection .stepsList .step .desc {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.43;
  }
  .enterprise .howItWorksSection .imageContainer {
    margin-top: 18px;
  }
  .enterprise .howItWorksSection .imageContainer > div {
    width: 314px;
    height: 362px;
  }
}

@media screen and (max-width: 767px) {
  #landingPage .howItWorksSection {
    padding: 60px 0px 80px;
  }
  .howItWorksSection .section {
    margin-left: auto !important;
  }
  .howItWorksSection .section .titleContainer .sectionSubTitle {
    display: none;
  }
  .howItWorksSection .worksContainer {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
  }
  .howItWorksSection .worksContainer .workPartWrapper {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
  }
  .howItWorksSection .worksContainer .workPartWrapper:last-child {
    margin-bottom: 0px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .header {
    flex-direction: row;
  }
  .howItWorksSection .worksContainer .workPartWrapper .image {
    height: 36px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    max-width: 160px;
    text-align: left;
    margin: 0px;
    margin-left: 20px;
  }
  .howItWorksSection .worksContainer .workPartWrapper .subTitle {
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.43;
    max-width: 260px;
    text-align: left;
  }

  .home .howItWorksSection .section .titleContainer {
    padding-left: 30px;
  }

  .enterprise .howItWorksSection .stepWrapper {
    height: 190px !important;
  }
  #landingPage .howItWorksSection .section {
    padding: 0 !important;
  }
  .howItWorksSection .section .titleContainer {
    padding: 0px 30px;
  }
  .enterprise .howItWorksSection .worksContainer {
    margin-top: 20px;
  }
  .enterprise .howItWorksSection .imageContainer {
    margin-top: 10px;
  }
  .enterprise .howItWorksSection .imageContainer > div {
    width: 244px;
    height: 281px;
  }
  .enterprise .howItWorksSection .imageContainer > div.slide4 {
    width: 100vw;
  }
  .enterprise .howItWorksSection .progressListContainer {
    display: flex !important;
    margin-top: 30px;
    justify-content: center;
  }
  .enterprise .howItWorksSection .progressListContainer .progressBar {
    width: 54px;
    height: 6px;
    background-color: rgba(36, 182, 70, 0.2);
    position: relative;
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  .enterprise
    .howItWorksSection
    .progressListContainer
    .progressBar:last-child {
    margin-right: 0px;
  }
  .enterprise .howItWorksSection .progressListContainer .progressBar.complete {
    background-color: #24b646;
  }
  .enterprise
    .howItWorksSection
    .progressListContainer
    .progressBar.active
    .progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 100%;
    background-color: #24b646;
  }

  .enterprise .howItWorksSection .seeInActionContainer .showVideoAction {
    font-size: 16px !important;
  }
}

#landingPage .meetAppSection {
  background-image: linear-gradient(to bottom, #00a234, #00a234),
    linear-gradient(
      313deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.25)
    );
  color: #fff;
  height: 500px;
}
.meetAppSection .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meetAppSection .intro {
  padding: 100px 33px 60px 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.meetAppSection .demoAndCarouselWrapper {
  display: flex;
  width: calc(50% + 207px);
}

.meetAppSection .intro .sectionTitle {
  color: #fff;
}
.meetAppSection .intro .sectionTitle .secondLine .dash {
  background-color: #fff;
}
.meetAppSection .intro .sectionSubTitle {
  max-width: 320px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 60px;
}
.meetAppSection .intro .action {
  width: 300px;
  border-radius: 25px;
  background-color: #feff73;
  text-align: center;
  color: #00a234;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.meetAppSection .appDemo {
  position: relative;
}
.meetAppSection .appDemo .appImg {
  margin-top: -140px;
  width: 414px;
  height: 640px;
  background-color: transparent;
  background: url('/images/landing/iphonecut-frame.png') center no-repeat;
  background-size: contain;
}
.meetAppSection .appDemo .video {
  position: absolute;
  top: 80px;
  left: 29px;
  width: 353px !important;
  height: 560px !important;
}
.meetAppSection .appDemo .video > iframe {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.meetAppSection .appDemo .video > div {
  background-color: transparent !important;
}
.meetAppSection .carousel {
  padding: 60px 33px;
  padding-right: 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.meetAppSection .carousel .progress > .bar {
  width: 40px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  margin-right: 16px;
  position: relative;
  transition: all 0.3s ease;
}
.meetAppSection .carousel .progress > .bar:last-child {
  margin-right: 0px;
}
.meetAppSection .carousel .progress > .bar.full {
  background-color: #feff73;
}
.meetAppSection .carousel .progress > .bar .progressing {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #feff73;
  transition: all 0.3s ease;
  animation-name: progressAnimation;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-play-state: paused;
}
.meetAppSection .carousel .slide {
  max-width: 300px;
  min-height: 240px;
}
.meetAppSection .carousel .slide .title {
  /* margin-top: 60px; */
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.45;
}
.meetAppSection .carousel .slide .subTitle {
  font-size: 18px;
  line-height: 1.56;
}
.meetAppSection .carousel .actions {
  display: flex;
}
.meetAppSection .carousel .actions .navBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 50px;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
}
.meetAppSection .carousel .actions .navBtn.disabled {
  pointer-events: none;
}
.meetAppSection .carousel .actions .navBtn > .icon {
  text-align: center !important;
}
.meetAppSection .carousel .actions .navBtn.prev {
  margin-right: 16px;
}
.meetAppSection .carousel .actions .action {
  display: none;
  width: 230px;
  border-radius: 25px;
  background-color: #feff73;
  text-align: center;
  color: #00a234;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 20px;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .meetAppSection .appDemo .appImg {
    margin-top: -140px;
    width: 414px;
    height: 640px;
    background-color: transparent;
    background: url('/images/landing/iphonecut-frame.png') center no-repeat;
    background-size: contain;
  }
  .meetAppSection .appDemo .video {
    position: absolute;
    top: 80px;
    left: 29px;
    width: 353px !important;
    height: 560px !important;
  }
  .meetAppSection .intro {
    padding-right: 0;
  }
  .meetAppSection .carousel {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  #landingPage .meetAppSection {
    background-image: none;
    color: #fff;
    height: auto;
  }
  #landingPage .meetAppSection .section {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
  }
  .meetAppSection .intro {
    width: 100%;
    max-width: 520px;
    padding: 0;
    margin: 0 auto 100px;
    margin-left: 180px;
    align-items: flex-start;
    height: 140px;
    display: none;
  }
  .meetAppSection .section .sectionTitle {
    color: #4a4a4a;
  }
  .meetAppSection .intro .sectionSubTitle {
    color: #4a4a4a;
    max-width: 520px;
    margin-bottom: 0;
  }
  .meetAppSection .intro .action {
    display: none;
  }
  .meetAppSection .demoAndCarouselWrapper {
    background-image: linear-gradient(to bottom, #00a234, #00a234),
      linear-gradient(
        313deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.25)
      );
    width: 100%;
    flex-direction: row-reverse;
    height: 480px;
    justify-content: space-between;
  }
  .meetAppSection .appDemo .appImg {
    margin-top: -62px;
    width: 354px;
    height: 542px;
  }
  .meetAppSection .appDemo .video {
    position: absolute;
    top: 59px;
    left: 43px;
    width: 267px !important;
    height: 483px !important;
  }
  .meetAppSection .carousel {
    padding-left: 60px;
  }
  .meetAppSection .carousel .actions .action {
    /* display: block; */
  }
}

@media screen and (max-width: 767px) {
  #landingPage .meetAppSection .section {
    align-items: flex-start;
  }
  .meetAppSection .section .sectionTitle.mobile {
    margin-bottom: 0px;
  }
  .meetAppSection .demoAndCarouselWrapper {
    flex-direction: column;
    height: 590px;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 0;
    background-color: #ffffff;
    background-image: none;
  }
  .meetAppSection .iphoneWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #00a234, #00a234),
      linear-gradient(
        334deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.25)
      );
  }
  .meetAppSection .intro {
    margin: 0 0 32px;
    padding-left: 30px;
    height: auto;
    /* padding: 0px 30px; */
    display: block;
    /* max-width: 580px; */
  }
  .meetAppSection .intro .sectionSubTitle {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    display: none;
  }
  .meetAppSection .carousel {
    padding: 0;
    height: 260px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }
  .meetAppSection .carousel .progress {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .meetAppSection .appDemo .appImg {
    margin-top: -62px;
    width: 212px;
    height: 326px;
  }
  .meetAppSection .appDemo .video {
    top: -14px;
    left: 27px;
    width: 157px !important;
    height: 278px !important;
  }
  .meetAppSection .appDemo .videoAsGif {
    position: absolute;
    top: -14px;
    left: 27px;
    width: 157px !important;
    height: 278px !important;
    background-size: cover;
  }
  .meetAppSection .carousel .progress > .bar {
    background-color: rgba(36, 182, 70, 0.2);
  }
  .meetAppSection .carousel .progress > .bar.full {
    background-color: #24b646;
  }
  .meetAppSection .carousel .progress > .bar .progressing {
    background-color: #24b646;
  }
  .meetAppSection .carousel .slide {
    min-height: 200px;
  }
  .meetAppSection .carousel .slide .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .meetAppSection .carousel .slide .subTitle {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .meetAppSection .carousel .actions {
    display: none;
  }
  .meetAppSection .carousel .actions .navBtn {
    display: none;
  }
  .meetAppSection .carousel .actions .action {
    margin: 0;
    width: 260px;
    display: none;
  }
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

#landingPage .beaconIntroSection {
  margin-top: 100px;
  margin-bottom: 100px;
}
.beaconIntroSection .video {
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  width: 100%;
  max-width: 960px;
  height: 432px;
}
.beaconIntroSection .video .videoPlayer {
  width: 100% !important;
  height: 100% !important;
}
.beaconIntroSection .video .beaconVideoPreview {
  background-image: url('/images/landing/beacon_video_thumbnail.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0px;
}

@media screen and (max-width: 1024px) {
  #landingPage .beaconIntroSection {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .beaconIntroSection .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .beaconIntroSection .section .titleContainer {
    /* margin-left: 120px; */
  }
  .beaconIntroSection .video {
    margin-top: 40px;
    height: 383px;
  }
}

@media screen and (max-width: 767px) {
  #landingPage .beaconIntroSection {
    margin: 80px 0;
  }
  .beaconIntroSection .section {
    padding: 0px !important;
    align-items: flex-start;
  }
  .beaconIntroSection .section .titleContainer {
    margin-left: 0px;
    /* padding: 0px 30px; */
  }
  .beaconIntroSection .video {
    height: 280px;
    margin-top: 40px;
    margin-bottom: 0;
  }
}

#landingPage .testimonialsSection {
  position: relative;
}
.testimonialsSection > .background {
  position: absolute;
  width: 100%;
  height: 380px;
  background-color: #f5f7fa;
  /* top: 700px; */
  bottom: 0;
  z-index: -1;
}
.testimonialsSection .testimonialsContainer {
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
}
.testimonialsSection .titleContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.testimonialsSection .titleContainer > .image {
  width: 67%;
  height: 410px;
  object-fit: contain;
  box-shadow: 14px 12px 38px 0 rgba(60, 145, 204, 0.17);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  /* margin: 0 auto; */
}
.testimonialsSection .testimonialsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -90px;
  height: 380px;
}
.testimonialsSection .testimonialsContainer > .feature {
  width: 55%;
  margin-top: 130px;
  margin-left: 60px;
  padding-right: 40px;
}

.testimonialsContainer > .feature .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}
.testimonialsContainer > .feature .title .text {
  font-size: 22px;
  font-weight: 600;
  color: #4a4a4a;
}
.testimonialsContainer > .feature .actions.nav {
  width: 116px;
  display: flex;
  justify-content: space-between;
}
.testimonialsContainer > .feature .actions.nav.mobile {
  display: none;
}
.testimonialsContainer > .feature .actions.nav > .navBtn {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #4a4a4a;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.testimonialsContainer > .feature .subTitle {
  font-size: 16px;
  line-height: 1.63;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial {
  width: 480px;
  height: 430px;
  box-shadow: 0 15px 45px 0 rgba(60, 145, 204, 0.26);
  background-color: #ffffff;
  padding: 30px 30px 50px 50px;
  margin-top: -116px;
}
.testimonialsContainer > .testimonial .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.testimonialsContainer > .testimonial .details > img {
  width: 86px;
  height: 80px;
  opacity: 0.5;
}
.testimonialsContainer > .testimonial .author .name {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.21;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial .author .designation {
  color: #3c91cc;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.45;
  margin-top: 10px;
}
.testimonialsContainer > .testimonial .quote {
  margin-top: 20px;
  margin-bottom: 38px;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.63;
  color: #4a4a4a;
}
.testimonialsContainer > .testimonial > .action {
  width: 100%;
  border-radius: 24px;
  background-color: #3c91cc;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  display: block;
}

@media screen and (max-width: 1024px) {
  .testimonialsSection .titleContainer {
    padding: 0px;
    margin-left: 120px;
  }
  .testimonialsSection .testimonialsContainer {
    justify-content: space-between;
  }
  .testimonialsSection .testimonialsContainer > .feature {
    max-width: 300px;
  }
  .testimonialsContainer > .testimonial {
    width: 400px;
  }
  .testimonialsContainer > .testimonial > .action {
    width: 300px;
  }
  .testimonialsSection > .background {
    height: 480px;
    top: 740px;
  }
}
@media screen and (max-width: 767px) {
  .testimonialsSection .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonialsSection > .background {
    top: 180px;
    bottom: auto;
    height: 480px;
  }
  .testimonialsSection .titleContainer {
    flex-direction: column-reverse;
    max-width: 520px;
    margin: 0 auto !important;
    width: 100%;
  }
  .testimonialsSection .titleContainer > .image {
    width: 100%;
    height: 200px;
  }
  .testimonialsSection .testimonialsContainer {
    flex-direction: column;
    max-width: 520px;
    margin: 0 auto;
    height: auto;
  }
  .testimonialsSection .testimonialsContainer > .feature {
    width: 100%;
    margin: 20px 0 0;
    padding: 0;
    max-width: 100%;
  }
  .testimonialsSection .testimonialsContainer > .testimonial {
    margin-top: 20px;
    width: 100%;
    padding: 30px 20px;
  }
  .testimonialsContainer > .testimonial .details {
    align-self: flex-start !important;
  }
  .testimonialsContainer > .testimonial .details > img {
    margin-top: -14px;
    width: 52px;
    height: 50px;
  }
  .testimonialsContainer > .testimonial > .action {
    width: 100%;
  }
  .testimonialsContainer > .feature .actions.nav.mobile {
    display: flex;
    margin-top: 20px;
  }
  .testimonialsContainer > .feature .actions.nav.desk {
    display: none;
  }
}

#landingPage .plansSection {
  margin-top: 100px;
}
.plansSection .section .titleContainer {
  /* padding-left: 36px; */
}
.plansSection .plansContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.home .plansSection .plansContainer {
  max-width: 1120px;
  margin: 60px auto 0;
}
.plansSection .plansContainer .planContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 0 3px 0 rgba(60, 145, 204, 0.17);
  border: 1px solid rgba(60, 145, 204, 0.3);
  color: #4a4a4a;
  transition: all 0.3s ease;
  background-color: #ffffff;
}
.home .plansSection .plansContainer .planContainer {
  width: 32%;
  max-width: 340px;
  height: 420px;
  padding: 50px 20px 30px;
}
.enterprise .plansSection .plansContainer .planContainer {
  width: 30%;
  max-width: 360px;
  min-width: 210px;
  height: 480px;
  padding: 70px 20px 30px;
}
.plansSection .plansContainer .planContainer:hover,
.plansSection .plansContainer .planContainer:focus {
  /* transform: translateY(-30px);
  box-shadow: 0 10px 30px 0 rgba(60, 145, 204, 0.17); */
}
.plansSection .planContainer .top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plansSection .planContainer .bottom {
  width: 100%;
}
.plansSection .planContainer .bottom .actionsContainer {
  height: 50px;
  margin-top: 20px;
}
.plansSection .plansContainer .planContainer > .image {
  height: 70px;
  width: auto;
}
.home .plansSection .plansContainer .planContainer .plan {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 600;
}
.plansSection .plansContainer .planContainer .features {
  line-height: 1.63;
  font-size: 16px;
}
.plansSection .plansContainer .planContainer .features .feature > span {
  margin-left: 4px;
}
.plansSection
  .plansContainer
  .planContainer
  .features
  .feature
  > span:first-child {
  margin-left: 0px;
}
.plansSection .plansContainer .planContainer .features .feature > span.bold {
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .planPrice {
  color: #24b646;
  font-size: 22px;
  font-weight: bold;
}
.plansSection
  .plansContainer
  .planContainer.enterprise
  .planPrice
  .priceContainer {
  display: inline-block;
}
.plansContainer .planContainer .planPrice .priceContainer .perMonth {
  font-weight: normal;
  margin-left: 12px;
}

.plansContainer .planContainer .planPrice .priceContainer .currency {
  font-weight: normal;
}
.plansSection .plansContainer .planContainer .planPrice .currency {
  top: -36px;
  margin-right: 8px;
  display: inline-flex;
  transform: translateY(-40px);
}
.plansSection .plansContainer .planContainer .planPrice .price {
  font-size: 72px;
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .decimalPoint,
.plansSection .plansContainer .planContainer .decimal {
  font-size: 36px;
}
.plansSection .plansContainer .planContainer .frequency {
  margin-top: 6px;
  line-height: 1.71;
  opacity: 0.7;
}
.plansSection .plansContainer .planContainer .frequency > span {
  margin-left: 4px;
}
.plansSection .plansContainer .planContainer .frequency > span.bold {
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .action {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border-radius: 25px;
  background-color: #24b646;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 0px auto;
}

.enterprise .plansSection {
  margin-top: 140px;
}
.enterprise .plansSection .section {
  padding: 0px;
}
.enterprise .plansSection .plansContainer .planContainer .plan {
  margin: 36px 0px 20px;
  font-size: 36px;
  font-weight: 600;
  line-height: 0.94;
}
.plansSection .plansContainer .planContainer .employeeCount {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}
.plansSection .plansContainer .planContainer .employeeCount .bold {
  font-weight: bold;
  margin-right: 5px;
}
.plansSection .plansContainer .planContainer .addOnInfo {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.71;
  color: #4a4a4a;
  opacity: 0.7;
}
.plansSection .plansContainer .planContainer.custom .addOnInfo {
  margin-top: 6px;
}
.plansSection .plansContainer .planContainer .addOnInfo .inline,
.plansSection .plansContainer .planContainer .addOnInfo .currency {
  margin-right: 5px;
}
.enterprise .plansSection .plansContainer .planContainer.custom .planPrice {
  font-size: 36px;
  width: 210px;
  line-height: initial;
  margin: 40px auto 0px;
}
.plansSection .plansContainer .planContainer .image.Family {
  width: 72px;
  height: 35px;
}
.plansSection .plansContainer .planContainer .image.Business {
  width: 152px;
  height: 37px;
}
.plansSection .plansContainer .planContainer .image.Platinum {
  width: 237px;
  height: 46px;
}

/* TABLET */
@media screen and (max-width: 1024px) {
  .plansSection .section .titleContainer {
    padding: 0px;
    /* margin-left: 120px; */
  }
  .plansSection .plansContainer {
    margin-bottom: 40px;
  }
  .home .plansSection .plansContainer {
    margin-top: 40px;
  }
  .home .plansSection .plansContainer .planContainer {
    height: 330px;
    padding: 30px 14px;
    width: 30%;
  }
  .plansSection .plansContainer .planContainer .features {
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
  }
  .plansSection .plansContainer .planContainer .planPrice .price {
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
  }
  .plansSection .plansContainer .planContainer .decimalPoint,
  .plansSection .plansContainer .planContainer .decimal {
    font-size: 24px;
    color: #24b646;
    font-weight: bold;
  }
  .plansSection .plansContainer .planContainer .planPrice .currency {
    transform: translateY(-20px);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
  }
  .plansSection .plansContainer .planContainer .planPrice .perMonth {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    margin-left: 12px;
  }
  .plansSection .plansContainer .planContainer.enterprise .planPrice > span {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
  }
  .plansSection .plansContainer .planContainer .actionsContainer,
  .plansSection .plansContainer .planContainer .action {
    width: 100%;
    height: 40px !important;
  }
  .plansSection .plansContainer .planContainer .action {
    max-width: 180px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
  }
  .plansSection .plansContainer .enterprise .frequency span:last-child {
    display: none;
  }
  .enterprise .plansSection .plansContainer .planContainer {
    height: 300px;
    padding: 30px 20px;
  }
  .plansSection .plansContainer .planContainer .image {
    display: none;
  }
  .enterprise .plansSection .plansContainer .planContainer .plan {
    margin: 0 0 10px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
  }
  .enterprise .plansSection .plansContainer .planContainer.custom .planPrice {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    max-width: 120px;
  }
  .plansSection .plansContainer .planContainer .employeeCount {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .plansSection .plansContainer .planContainer .employeeCount .bold {
    font-weight: bold;
    margin-right: 5px;
  }
  .plansSection .plansContainer .planContainer .addOnInfo {
    margin-top: 0;
  }
}

/* MOBILE */
@media screen and (max-width: 767px) {
  #landingPage .plansSection {
    margin-bottom: 60px;
  }
  .plansSection .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .plansSection .section .titleContainer {
    margin: 0px;
  }
  .plansSection .plansContainer {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
  }
  .plansSection .plansContainer .priceWrapper .price,
  .plansSection .plansContainer .priceContainer {
    color: #24b646;
  }
  .plansSection .plansContainer .planContainer {
    width: 100% !important;
    min-width: 260px;
    margin-bottom: 20px;
    padding: 20px 16px !important;
    height: auto !important;
    display: flex;
    flex-direction: row;
  }
  .home .plansSection .plansContainer .planContainer {
    align-items: flex-start;
  }
  .plansSection .plansContainer .planContainer:last-child {
    margin-bottom: 0px;
  }
  .plansSection .plansContainer .planContainer .bold {
    font-weight: 600;
  }
  .plansSection .plansContainer .planContainer .planName {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    text-align: left;
  }
  .plansSection .plansContainer .planContainer .features {
    text-align: left;
    font-size: 12px;
    line-height: 1.33;
    margin-top: 10px;
  }
  .plansSection .plansContainer .planContainer .features span {
    margin-right: 4px;
  }
  .plansSection .plansContainer .planContainer .priceWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .perMonth {
    font-weight: normal;
    margin-left: 8px;
  }
  .plansSection .plansContainer .planContainer .frequency {
    font-size: 12px;
    line-height: 1.33;
    margin-top: 4px;
  }
  .plansSection .plansContainer .planContainer .price {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    /* display: flex; */
    justify-content: flex-end;
  }
  .plansSection .plansContainer .planContainer .decimalPoint,
  .plansSection .plansContainer .planContainer .decimal {
    font-size: 20px;
    color: #24b646;
    font-weight: bold;
  }
  .plansSection .plansContainer .planContainer .currency {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    margin-right: 6px;
  }
  .plansSection .plansContainer .planContainer .action {
    width: 200px;
  }
  .plansSection .sectionFooter {
    max-width: 100%;
    font-size: 16px;
  }
}

.featuresSection {
  margin-top: 280px;
  height: 680px;
  background-color: #f5f7fa;
  position: relative;
}
.featuresSection .section {
  position: absolute;
  left: calc(50vw - 590px);
  top: -220px;
  width: 1180px !important;
}
.featuresSection .section .titleContainer {
  margin: 0 auto;
  /* margin-left: -40px; */
}
.featuresSection .featuresList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 60px;
}

.featuresSection .featureCard {
  width: 360px;
  min-height: 280px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 50px 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.featuresSection .featureCard .imageContainer {
  display: flex;
  align-items: center;
}
.featuresSection .featureCard .featureImage {
  height: 50px;
  width: auto;
}
.featuresSection .featureCard .featureImage.fraud {
  width: 53px;
  height: 40px;
}
.featuresSection .featureCard .featureImage.accuracy {
  width: 48px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.beacon {
  width: 34px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.storage {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.reports {
  width: 51px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.tracking {
  width: 52px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.dashboard {
  width: 58px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.multi-team {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.report_workflow {
  width: 51px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.fraud_prevention {
  width: 53px;
  height: 40px;
}
.featuresSection .featureCard .featureImage.secure_storage {
  width: 43px;
  height: 50px;
}
.featuresSection .featureCard .featureImage.integrations {
  width: 50px;
  height: 50px;
}
.featuresSection .featureCard .featureTitle {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 30px;
  margin-bottom: 10px;
}

.featuresSection .featureCard .featureDescription {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
}
.enterprise .featuresSection {
  margin-top: 120px;
  background-color: #fff;
  height: 780px;
}
.enterprise .featuresSection .section {
  top: 0px;
}
@media screen and (max-width: 1200px) {
  .featuresSection {
    height: 1060px !important;
  }
  .enterprise .featuresSection {
    height: 1170px !important;
  }
  .featuresSection .section {
    left: calc(50vw - 410px) !important;
    width: 820px !important;
  }
}
@media screen and (max-width: 1024px) {
  .featuresSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .featuresSection .featuresList,
  .featuresSection .section .titleContainer {
    width: 100% !important;
  }
  .featuresSection .section .titleContainer {
    margin: 0px !important;
  }
  .featuresSection .section .titleContainer .sectionSubTitle {
    margin-left: 120px;
  }
  .enterprise .featuresSection {
    margin-top: 120px !important;
  }
}
@media screen and (max-width: 767px) {
  .featuresSection {
    height: 1360px !important;
  }
  .enterprise .featuresSection {
    height: 1410px !important;
  }
  .featuresSection .section {
    left: calc(50vw - 180px) !important;
    padding: 0 30px !important;
    top: -180px;
    width: 360px !important;
  }
  .featuresSection .featureCard {
    width: 300px !important;
    min-height: 200px !important;
    padding: 40px 20px 32px !important;
    margin: 0px 0px 10px 0px !important;
  }
  .featuresSection .featureCard .firstPart {
    display: flex;
    align-items: center;
  }
  .featuresSection .featureCard .featureTitle {
    font-size: 18px;
    line-height: 1.33;
    margin: 0px 0px 0px 16px;
  }
  .featuresSection .featureCard .featureDescription {
    font-size: 14px;
    line-height: 1.43;
    margin-top: 18px;
  }
  .featuresSection .section .sectionTitle.mobile {
    margin-bottom: 0px !important;
  }
  .featuresSection .section .titleContainer {
    margin: 0px !important;
  }
  .featuresSection .section .titleContainer .sectionSubTitle {
    display: none;
  }
  .featuresSection .featuresList {
    margin-top: 20px !important;
    max-width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .featuresSection .section {
    padding: 0px 10px !important;
  }
  .featuresSection .section .titleContainer {
    padding-left: 20px !important;
  }
  .featuresSection .featureCard,
  .featuresSection .featureCard .featureDescription {
    height: auto;
  }

  .enterprise .featuresSection .section {
    position: inherit;
  }
}

.section {
}

.section .sectionTitle {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.27;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section .sectionTitle.white {
  color: #ffffff;
}
.section .sectionTitle .secondLine {
  display: flex;
  align-items: center;
}
.section .sectionTitle .secondLine .dash {
  width: 100px;
  height: 6px;
  background-color: #4a4a4a;
  margin-right: 20px;
}
.section .sectionTitle.white .secondLine .dash {
  background-color: #ffffff;
}
.section .sectionTitle.white .dash {
  background-color: #ffffff;
}

/* Upgrade Pro Section START */

.upgradeProSection {
  background: url('/images/landing-pro-upgrade-background.png') center no-repeat;
  background-size: cover;
  height: 520px;
  margin-bottom: 40px;
}
.upgradeProSection.mobile {
  height: auto;
}
.upgradeProSection .section {
  display: flex;
  justify-content: space-between;
}
.upgradeProSection .left {
  height: 100%;
  padding-top: 80px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.upgradeProSection .left .perksContainer {
  max-width: 480px;
  margin-left: 80px;
  margin-top: 40px;
}
.upgradeProSection .left .perksInfo {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 26px;
}
.upgradeProSection .left .perksList {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
  padding-left: 20px;
}
.upgradeProSection .left .perksList li {
  margin: 13px 0;
}
.upgradeProSection .left .perksList li:first-child {
  margin-top: 0;
}
.upgradeProSection .left .perksList li:last-child {
  margin-bottom: 0;
}

#landingPage .upgradeProSection.mobile .section {
  flex-direction: column;
  padding-top: 60px !important;
  padding-bottom: 30px !important;
}
.upgradeProSection.mobile .titleWrapper .sectionSubTitle {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
}
.upgradeProSection.mobile .plansContainer {
  margin-top: 40px;
}
.upgradeProSection.mobile .plansContainer .planContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0 3px 0 rgba(60, 145, 204, 0.17);
  border: solid 1px rgba(60, 145, 204, 0.3);
  background-color: #ffffff;
  padding: 16px;
  color: #4a4a4a;
}
.upgradeProSection.mobile .plansContainer .planContainer:first-child {
  margin-bottom: 10px;
}
.upgradeProSection.mobile .plansContainer .planContainer .planName {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
}
.upgradeProSection.mobile .plansContainer .planContainer .frequency {
  line-height: 1.33;
  font-size: 12px;
  color: #9b9b9b;
}
.upgradeProSection.mobile .plansContainer .planContainer .details .frequency {
  font-weight: 500;
  margin-top: 10px;
}
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .frequency {
  text-align: right;
}
.upgradeProSection.mobile .plansContainer .planContainer .priceWrapper {
  color: #24b646;
}
.upgradeProSection.mobile .plansContainer .planContainer .priceWrapper .price {
  text-align: right;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.11;
  /* display: flex; */
  justify-content: flex-end;
  margin-left: 4px;
}
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .decimalPoint,
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .decimal {
  font-size: 20px;
  font-weight: bold;
}

.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .price
  > .currency {
  font-size: 28px;
  line-height: 1.57;
  font-weight: normal;
  margin-right: 4px;
}

.upgradeCard {
  width: 360px;
  height: 480px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(170, 220, 255, 0.6);
  background-color: #ffffff;
  padding: 80px 48px 80px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  margin-right: 40px;
}
.upgradeCard .image {
  width: 90px;
  height: 62.3px;
  margin-bottom: 14px;
}
.upgradeCard .priceContainer {
  height: 70px;
  display: flex;
  align-items: flex-end;
}
.upgradeCard .priceContainer span {
  color: #24b646;
}
.upgradeCard .priceContainer .currency {
  align-self: flex-start;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
.upgradeCard .priceContainer .price {
  font-size: 80px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: normal;
  text-align: right;
}
.upgradeCard .priceContainer .perMonth {
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin-left: 12px;
}
.upgradeCard .switchContainer {
  display: flex;
  align-items: center;
}
.upgradeCard .switchContainer .switch {
  padding: 0 14px;
}
.upgradeCard .switchContainer .switchLabel {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #9b9b9b;
}
.upgradeCard .switchContainer .switchLabel.active {
  color: #4a4a4a;
  font-weight: bold;
}

.upgradeCard .actions {
  width: 100%;
}
.upgradeCard .actions .upgradeBtn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #24b646;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

/* Upgrade Pro Section END */

/* Questions Section START */

.questionsSection {
  margin-top: 120px;
}
.questionsSection .questionsContainer {
  height: 240px;
  width: 100%;
  background-color: #f5f7fa;
  padding: 64px 120px 64px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
}
.questionsSection .questionsContainer .left {
}
.questionsSection .quesitonsSectionsContent {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.questionsSection .questionsContainer .center .image {
  width: 146px;
  height: 155px;
  opacity: 0.6;
}
.questionsSection .questionsContainer .right {
  width: 200px;
  height: 104px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.45;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.questionsSection .questionsContainer .right > a {
  cursor: pointer;
  text-decoration: none !important;
  color: #00a234;
}

/* Questions Section END */

.enterprise .customersSection {
  width: 100%;
  height: 400px;
  background-color: #f5f7fa;
  display: flex;
  margin-top: 100px;
}
#landingPage.enterprise .customersSection .section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* min-width: 1100px; */
  padding: 0px 30px;
}
.enterprise .customersSection .logosContainer {
  width: 50%;
  max-width: 464px;
  height: 100%;
  background-image: url('/images/landing/customers2x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* margin-right: 172px; */
}
.enterprise .customersSection .textContainer {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.enterprise .customersSection .textContainer .desc {
  font-size: 22px;
  line-height: 1.45;
  padding-left: 20px;
}
.enterprise .customersSection .textContainer .desc {
  margin: 40px 0px 0px;
  font-size: 20px;
  line-height: 1.5;
  padding-left: 0px;
}
.enterprise .customersSection .textContainer .title,
.enterprise .customersSection .textContainer .desc .bold {
  font-weight: bold;
}
.enterprise .customersSection .textContainer .desc .bold {
  margin-right: 5px;
}
.enterprise .questionsSection {
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .upgradeProSection .section {
    padding: 0px 40px !important;
  }
  .upgradeProSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .upgradeProSection .left {
    padding-left: 0px !important;
  }
  .upgradeProSection .left .perksContainer {
    margin-left: 20px;
  }
  .upgradeCard {
    width: 280px;
    padding: 80px 30px;
    margin-right: 0px;
  }

  .enterprise .customersSection {
    height: auto;
  }
  #landingPage.enterprise .customersSection .section {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .enterprise .customersSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .enterprise .customersSection .textContainer {
    margin-bottom: 42px;
    width: 100%;
    height: auto;
  }
  .enterprise .customersSection .logosContainer {
    width: 100%;
    height: 320px;
  }
  .enterprise .customersSection .textContainer .desc {
    font-size: 16px;
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .enterprise .plansSection {
    margin-bottom: 60px;
  }
  .enterprise .plansSection.mobile .plansContainer {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 520px;
    align-self: center;
  }
  .enterprise .plansSection.mobile .section .titleContainer .sectionSubTitle {
    margin-left: 0px !important;
  }
  .enterprise .plansSection.mobile .plansContainer .planContainer {
    padding: 16px !important;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .details {
    padding-top: 6px;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .planName {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    text-align: left;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .employeeCount {
    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .price {
    text-align: right;
    color: #24b646;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    /* display: flex; */
    justify-content: flex-end;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .customPrice {
    text-align: right;
    color: #24b646;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    max-width: 100px;
  }
  .enterprise
    .plansSection.mobile
    .planContainer
    .basicInfo
    .price
    > .currency {
    font-size: 14px;
    line-height: 1.57;
    font-weight: normal;
    margin-right: 4px;
  }
  .enterprise .plansSection .plansContainer .planContainer .frequency {
    margin-top: 0px;
    font-size: 12px;
  }
  .enterprise .plansSection .plansContainer .planContainer .addOnInfo {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    width: 100%;
  }
}
@media screen and (max-width: 350px) {
  .enterprise .customersSection .logosContainer {
    height: 200px;
  }
}

.carouselContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.carouselContainer .carousel {
  width: 100%;
  height: 100%;
}
.carouselContainer .list {
  display: inline-flex;
  transition: 0.4s ease-out all;
}
.carouselContainer .navBtn {
  position: absolute;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  text-align: center;
}
.carouselContainer .navBtn.prevNavBtn {
  left: 0;
}
.carouselContainer .navBtn.nextNavBtn {
  right: 0;
}
.carouselContainer .navBtn:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.carouselContainer .navBtn.hide {
  color: #aaa;
  pointer-events: none;
}
.carouselContainer .navBtn > .icon {
  text-align: center !important;
  cursor: pointer;
  margin: 0px !important;
}

#page .helpPageContainer {
  margin-top: 0px;
}
.helpPageContainer {
  margin-top: 80px;
  height: calc(100% - 80px);
}
.helpPageContainer .helpPageHeaderContainer .pageName {
  font-size: 20px;
}
.helpPageContainer .helpPageHeaderContainer .helpButtonMail {
  display: none !important;
}
#page .helpPageContainer .content {
  height: 100%;
}
.helpPageContainer .content {
  max-width: 1280px;
  height: calc(100vh - 160px);
  margin: 0 auto;
  padding: 40px 50px 0px;
  display: flex;
  justify-content: space-between;
}
.helpPageContainer .searchListContainer {
  width: 700px;
}
.searchListContainer .searchInput input {
  height: 50px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 0.88;
}
.searchListContainer .itemsListContainer {
  margin-top: 20px;
  max-height: calc(100% - 70px);
  overflow: auto;
  overflow-x: hidden;
}
.itemsListContainer .itemContainer .question {
  background-color: #fafafa;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.itemsListContainer .itemContainer .question > .icon {
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.5);
}
.itemsListContainer .itemContainer .question > .icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.itemsListContainer .itemContainer .answer {
  padding: 20px 30px 40px;
  font-size: 14px;
  line-height: 1.5;
}

.itemsListContainer .itemContainer .answer li {
  margin: 0 0 3px 0;
}

.supportSegment {
  max-width: 390px;
}
.supportSection .supportButtons {
  display: flex;
  justify-content: space-between;
}
.helpButtonIcon {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white;
}
.helpButtonIconTwitter {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white;
}
.helpButton {
  height: 36px;
  width: 48%;
  border-radius: 4px !important;
  margin-bottom: 34px !important;
  font-weight: normal !important;
  font-size: 13px !important;
  white-space: nowrap;
}
.helpButtonTwitter {
  background: #00aced !important;
  color: white !important;
}
.helpButtonMail {
  background: #21ba45 !important;
  color: white !important;
}
.to-learn-more-about {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.to-learn-more-about .text-style-1 {
  color: #2185d0;
}
.findOut {
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.3px;
  color: #000000;
  border-radius: 4px;
  background-color: #f9fafb;
  padding: 8px 20px;
  max-width: 390px;
}
.findOut > p > a {
  font-weight: 600;
}
.segment-mobile {
  margin: 10px !important;
}
.psngrSupport {
  height: 27px;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.dont-want-to-search {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

@media only screen and (max-width: 1280px) {
  .helpPageContainer .content {
    justify-content: center;
  }
  .helpPageContainer .supportSection {
    display: none;
  }
  .helpPageContainer .helpPageHeaderContainer .helpButtonMail {
    display: block !important;
    margin: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .helpPageContainer .searchListContainer {
    height: 100%;
  }
  .helpPageContainer .supportSection {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

/* tablet */
@media only screen and (max-width: 991px) {
  .supportSegment {
    max-width: 300px;
  }
}

/* iPad */
@media only screen and (max-width: 768px) {
  .helpPageContainer .content {
    padding: 40px 20px 0px;
  }
  .helpPageContainer .searchListContainer {
    width: 100%;
  }
  .supportSegment {
    width: 100%;
    max-width: 400px;
  }
  .supportSection {
    max-width: 100% !important;
  }
}

#loadingPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingPage .loading {
  width: 100%;
  height: 100%;
}

.badgeIconTooltip.popup.visible {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #343434;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.54;
  font-size: 13px;
  padding: 12px 14px;
}
.badgeIconTooltip.popup.visible:before {
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #343434;
}
.ui.page.modals {
  display: flex !important;
}

#container_dashboard {
  color: #000000;
  font-size: 14px;
  line-height: 1.43;
}

/* Main Menu START */

#menu {
  margin: 0 auto;
  border-bottom: 0;
  padding: 0px 50px;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
#menu .item > a {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: black;
}
#menu .item > a .ui.green.label {
  margin-left: 10px;
  padding: 5px 10px;
}
#navigation .button {
  padding: 14px 15px 16px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 14px !important;
  height: 50px;
  border-radius: 0;
  /* color: #000000; */
}
#navigation .createCompanyBtn {
  border-radius: 4px;
  height: 40px;
}
#navigation .reportsDropdownTitle {
  display: flex;
  justify-content: space-between;
}
#navigation .reportsDropdownTitle .name {
  display: flex;
  align-items: center;
  width: 60px;
}
#navigation .reportsDropdownTitle .name.en {
  width: 48px;
}
#navigation .reportsDropdownTitle .name.nl {
  width: 70px;
}
#navigation .active .reportsDropdownTitle {
  color: #000000 !important;
}
#navigation .vehiclesDropdownTitle,
#navigation .tripsDropdownTitle {
  display: flex;
  align-items: center;
}
#navigation .tripsDropdownTitle .badgeCount {
  height: 19px;
  padding: 2px 10px 0;
  display: flex;
  align-items: center;
}
#navigation .active {
  background-color: #f3f3f3 !important;
  font-weight: bold !important;
  color: #000000 !important;
}
#navigation .active .dropdown.icon {
  color: #000000 !important;
  font-weight: bold !important;
}
#menu.ui.secondary.pointing.menu > .active.item {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
}
#navigation #menu > a.item,
#navigation #menu > div.item {
  align-self: auto;
  padding-left: 15px;
  padding-right: 15px;
}
#navigation #menu > div.item.profileItem {
  padding-right: 0;
}
#navigation #menu > a.item.tripsTab {
  position: relative;
  padding: 0;
}
#navigation #menu > a.item.tripsTab .badgeCount {
  margin: 0px;
  margin-left: 1em;
  height: 20px;
  padding-top: 1px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#navigation #menu > a.item.tripsTab .tooltipIcon {
  position: absolute;
  right: -4px;
}
#navigation .tripsItemContainer {
  padding: 0 14px;
  /* padding-left: 28px; */
  display: flex;
  align-items: center;
}
#navigation #menu .reportsItem,
#navigation #menu .vehiclesItem,
#navigation #menu .profileItem {
  padding: 0 !important;
  /* padding: 0 14px !important; */
}

#navigation #newReports {
  background-color: #21ba45;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 8px 0;
  margin-left: 8px;
}

/* Profile Dropdown */
#profileDropdown {
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
#profileDropdown .menu.transition.visible {
  width: 300px;
  right: 0px;
  top: 50px;
  left: auto;
  /* margin-top: -1px; */
}
#profileDropdown .profileDropdownTitle {
  display: flex;
  align-items: center;
}
#profileDropdown .profileDropdownTitle .name {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#profileDropdown .profileDropdownTitle > .userImage,
#profileDropdown .profileDropdownTitle > svg {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 6px;
}
#profileDropdown .profileDropdownTitle > span {
  font-weight: 400;
  line-height: 1.43;
}
#profileDropdown .profileDropdownTitle > span,
.ui.dropdown > .dropdown.icon {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
#profileDropdown .noPadding {
  padding: 0 !important;
  height: auto !important;
}
#profileDropdown .profileDropdownHeader {
  background-color: #f3f3f3 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 30px 10px;
  width: 100%;
  position: relative;
  z-index: 100;
}
#profileDropdown .profileDropdownHeader > .userImage,
#profileDropdown .profileDropdownHeader > svg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0;
}
#profileDropdown .userImage {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
}
#profileDropdown .profileDropdownHeader > div {
  margin: 10px 0;
  width: 100%;
  white-space: initial;
  text-align: center;
}
#profileDropdown .profileDropdownHeader > div.email {
  font-weight: 400;
}
#profileDropdown .profileDropdownHeader > span,
#profileDropdown .profileDropdownHeader > span:hover {
  font-size: 14px;
  font-weight: normal !important;
  color: #899ba7 !important;
}
#profileDropdown .item {
  display: flex;
  justify-content: center;
  height: 50px;
  transition: all 0.3s ease !important;
}
/* #profileDropdown .item.active {
  background-color: transparent !important;
} */
#profileDropdown .item > span {
  font-weight: 600;
}
#profileDropdown .item > span.badge {
  width: 22px;
  height: 20px;
  background-color: #db2828;
  text-align: center;
  line-height: 20px;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-left: 8px;
  border-radius: 4px;
}
#menu .profileItem {
  padding: 0 10px;
}
#menu .vehiclesItem span,
#menu .reportsItem span {
  font-weight: normal;
  color: #000000;
}
#menu .vehiclesItem span {
  width: 62px;
}
#menu .profileItem span {
  font-weight: bold;
}
#menu .profileItem:hover span,
#menu .reportsDropdownItem:hover span,
#menu .tripsTab:hover span,
#menu .reportsItem:hover .reportsTrigger span,
#menu .reportsItem:hover .dropdown.icon,
#menu .vehiclesItem:hover .vehiclesDropdownTitle span,
#menu .vehiclesItem:hover .dropdown.icon,
#menu .profileItem.active span,
#menu .reportsDropdownItem.active span,
#menu .profileItem:hover .dropdown.icon {
  font-weight: bold;
  color: #000000;
  transition: all 0.2s ease;
}
#menu .reportsItem {
  padding: 0 !important;
}
#reportsDropdown,
#tripsDropdown {
  width: 100%;
  height: 100%;
}
#reportsDropdown .menu.visible {
  /* margin-top: -4px; */
  min-width: 100%;
}
#tripsDropdown .menu.visible {
  min-width: 100%;
  width: 160px;
}
#reportsDropdown .menu.visible.de .name {
  width: 100px;
}
#reportsDropdown .menu.visible.nl .name {
  width: 100%;
}
#reportsDropdown .menu.visible.it .name {
  width: 125px;
}
#reportsDropdown .menu.visible.es .name {
  width: 115px;
}
#reportsDropdown .menu.visible.en .name {
  width: 85px;
}
#reportsDropdown .menu.visible.fr .name {
  width: 110px;
}
#tripsDropdown .menu.visible.de {
  width: 130px;
}
#tripsDropdown .menu.visible.nl {
  width: 130px;
}
#tripsDropdown .menu.visible.it {
  width: 140px;
}
#tripsDropdown .menu.visible.es {
  width: 140px;
}
#tripsDropdown .menu.visible.en {
  width: 110px;
}
#tripsDropdown .menu.visible.fr {
  width: 135px;
}

.ui.menu .ui.dropdown .menu > .item > span {
  font-weight: 400 !important;
  transition: all 0.2s ease;
}
.ui.menu .ui.dropdown .menu > .item:hover {
  background-color: transparent !important;
}
.ui.menu .ui.dropdown .menu > .item:hover > span {
  font-weight: bold !important;
}
.ui.menu .ui.dropdown .menu > .active.item {
  border: none;
}
.ui.menu .ui.dropdown .menu > .active.item > span {
  font-weight: bold !important;
}

.ui.dropdown .menu > .divider {
  margin: 0 auto;
  width: 95%;
  height: 2px;
}

.badgeIconTooltip {
  width: 280px;
}
.badgeIconTooltip.trips,
.badgeIconTooltip.report {
  width: 300px;
}
.badgeIconTooltip.report .approval.clickable {
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}
.tripsLeft {
  height: 24px;
  /* width: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px !important;
}
.tripsLeft span {
  vertical-align: middle;
  font-weight: 400;
}
.tripsLeft.green {
  background-color: #24b646;
}
.tripsLeft.golden {
  background-color: #fbbd08 !important;
}
.tripsLeft.red {
  background-color: #f24a4a;
}
.badgeIconTooltip .content .upgradeInfo {
  width: 150px;
  font-size: 13px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.badgeIconTooltip .content .upgradeAction {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  display: block;
}
.badgeIconTooltip .content .upgradeAction:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #ffffff;
          text-decoration-color: #ffffff;
}

.reportsBadge {
  margin-left: 1em;
  padding: 0 10px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db2828;
  border-color: #db2828;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

/* Main Menu END */

#trips #status {
  font-weight: bold;
  font-size: 18px;
}
#trips #status a {
  font-weight: normal;
  font-size: 11.2px;
  color: #2185d0;
}

.downloadHeader {
  margin-right: 0 !important;
}

.appTheme {
  color: #24b626;
}
body #root button.appTheme {
  background-color: #24b626;
  color: white;
}
i.circular.icon.appTheme {
  box-shadow: 0 0 0 0.1em #24b626 inset;
}
.filterBar {
  /* max-width: 1120px; */
  margin-left: 50px;
  /* overflow-x: auto; */
  /* white-space: nowrap; */
}
.filterBar::-webkit-scrollbar {
  /* display: none; */
  height: 2px;
}
.filterBar .floatRight {
  margin-right: 0 !important;
}
.filterBarContainer {
  background-color: #f3f3f3;
  /* background-color: #f9fafb; */
  padding: 20px;
  width: 100vw;
  margin-left: -60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  /* margin-bottom: 20px; */
}
.clearFilter {
  display: inline-block;
}
#psngrWebApp .filterBar > div {
  margin-right: 20px;
  vertical-align: top;
  display: inline-block;
}
#psngrWebApp .filterBar > .group,
#psngrWebApp .filterBar > .group > * {
  display: inline-block;
  vertical-align: top;
}
#psngrWebApp .filterBar > .group > * {
  margin-right: 20px;
}

#psngrWebApp .filterBar > .group.right {
  float: right;
}
#psngrWebApp .filterBar > .group.right .statsContainer {
  margin-right: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  padding-top: 10px;
}
#psngrWebApp .filterBar .statsContainer > .stat:after {
  content: ',';
  margin-right: 4px;
}
#psngrWebApp .filterBar .statsContainer > .stat:last-child:after {
  content: '';
  margin-right: 0px;
}
.ui.basic.button.clearButton {
  background-color: #fff !important;
  height: 38px;
}

.tripsPage {
  height: 100%;
}

/* Footer START */
.footerContainer {
  background-color: #f3f3f3;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);
}
.footer {
  /*margin: auto;*/
  padding: 20px 60px;
  height: 100px;
}
.footer .left,
.footer .right {
  vertical-align: top;
  display: inline-block;
  color: #767676;
}
.footer .left .distance,
.footer .left .expenses {
  font-size: 18px;
}
.footer .left .expenses > span:nth-child(2) {
  margin-left: 6px;
}
.footer .left .titleWrapper > * {
  display: inline-block;
}
.footer .left .titleWrapper .header {
  margin-right: 12px;
}
.footer .left .subTitleWrapper > div {
  display: inline-block;
  margin-right: 20px;
}
.footer .right .tooltipIcon {
  display: inline-block;
}
.footer .right .button {
  margin-left: 20px;
}

.footer .download,
.footer .classify {
  width: 140px;
  height: 36px;
}
.footer .classify.disabled {
  pointer-events: none;
  opacity: 0.45;
}
.footer .classify .text {
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.footer .right {
  margin-top: 15px;
  float: right;
}

#listFooter {
  height: 80px;
  box-shadow: inset 0 -1px 0 0 #dedede;
  background-color: #e8f2fa;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  padding: 28px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}
#listFooter .footer {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-right: 44px;
  padding-left: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#listFooter .footer .rightSection > * {
  text-align: right;
}
#listFooter .footer .rightSection .currency {
  min-width: 114px;
  padding-right: 18px;
  padding-left: 10px;
}
#listFooter .footer .rightSection .distance {
  min-width: 100px;
  margin-right: 22px;
}
#listFooter .footer .rightSection .distance .unit {
  margin-left: 5px;
}
#listFooter .footer .rightSection .time {
  margin-right: 40px;
}
#listFooter .footer .rightSection {
  display: flex;
}
#listFooter .footer .rightSection > * {
  display: block;
}
#listFooter .footer .rightSection > *:last-child {
  margin-right: 0px;
}
#listFooter.reportsFooter .footer {
  padding-right: 0px;
}
#listFooter.reportsFooter .footer .rightSection .currency {
  padding-right: 50px;
  min-width: 140px;
}
#listFooter.reportsFooter .footer .rightSection .tripsCount {
  margin-right: 12px;
}

/* Footer END */

.ui.popup.tripDownloadPopup {
  padding: 0;
  min-width: 380px;
}

.tripDownloadPopup .topHalf {
  background-color: #f9fafb;
  padding: 30px 28px 25px 28px;
}

.tripDownloadPopup .bottomHalf {
  padding: 30px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tripDownloadPopup .bottomHalf.addHeight {
  height: 190px;
}

.tripDownloadPopup .bottomHalfReportsCheckbox {
  display: flex;
  flex-direction: column;
}
.tripDownloadPopup .bottomHalfReportsCheckbox > .checkbox {
  margin-bottom: 10px;
}

.tripDownloadPopup .bottomHalfButtonsContainer {
  display: flex;
  justify-content: space-around;
}

.tripDownloadPopup .bottomHalfButtonsContainer > .button {
  margin: 0;
}
.inputHeader {
  margin-left: -5px;
  min-width: 100px;
}

.inputHeader > input {
  border: 1px solid transparent !important;
  padding: 5px !important;
  background: transparent !important;
}

.inputHeader > input:focus {
  border: 1px solid #cce2ff !important;
}
.inputDateRangeContainer {
  background-color: #2185d0;
  height: 40px;
  display: flex;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.inputDateRangeContainer.roundEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.inputDateRangeContainer > .calendarImage {
  margin: 11px;
  margin-right: 0;
}
.inputDateRangeWhite {
  width: 100%;
  display: flex;
  align-items: center;
}
.inputDateRangeWhite > input {
  padding-right: 38px !important;
  cursor: pointer;
}
.inputDateRangeWhite > .calendarImageBlue {
  width: 16px;
  height: 16px;
  margin-left: -28px;
}
.inputDateRange > input {
  width: 190px;
}
.dateRangePicker .calendarImage {
  width: 18px;
  height: 18px;
}
/* .dateRangePicker .calendarImage.small {
  width: 18px;
  height: 18px;
} */
.dateRangePicker {
  position: relative;
}
.dateRangePicker .button {
  background-color: #2185d0;
  color: #fff;
  height: 40px;
  margin-right: 0;
}
.dateRangePicker .button:hover,
.dateRangePicker .button:active,
.dateRangePicker .button:focus {
  background-color: #2185d0 !important;
  color: #fff !important;
}
.dateRangePicker .inputDateRange > input {
  width: 210px;
  background-color: #2185d0 !important;
  border: none;
  /* color: #fff !important; */
  font-size: 12px;
  font-weight: bold;
  width: 180px;
  height: 40px;
  cursor: pointer;
  color: transparent !important;
  text-shadow: 0 0 0 #fff !important;
}
.dateRangePicker .clearDateRange {
  position: absolute;
  right: 2px;
  height: 100%;
  line-height: 42px;
  cursor: pointer;
  top: 0;
  /* padding-right: 7px; */
}
.dateRangePicker.withRange i.icon {
  color: #d8d8d8;
}
.DayPicker-Day--disabled {
  background-color: #ffffff;
}

.dropdownFilter {
  background-color: #e0e1df !important;
  border: none !important;
}
.dropdownFilter.withSelection,
.inputDateRange.withSelection > input {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.dropdownFilter .text {
  color: rgba(0, 0, 0, 0.6);
}

.addTagHeader {
  margin: 5px 0 5px 5px;
}

.addTagHeader > input {
  width: 165px;
}

.footer .classify > div {
  margin-right: 0 !important;
}
#menu .logoItem {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.psngrLogo {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA8CAYAAAAdUliaAAAAAXNSR0IArs4c6QAABJhJREFUaAXtmWtoHFUUx/93ZvbRfaS72XQNaovU4AtUBD/1k2CFqB8qQoNfirWJRUREUPCFIFIEQWqVggrqh1AkVm2VQqMI+lkEEfFBJQ8rxdqwafc97uzMXM8NbjO72dncmW5mp5ALl7l37j3n/O7J3Ztzz7C9x/eqP+ufnUqn2O5oFBH0t3DThN000TAtVCwTBaOJr5ta/M2z++rn/ZhiN0+z2VyWjfsR9ivDAV6rolBv4EuUdz419/RcQ1YXu/tzxdgAz8raR6MBo1jGkfkD9vMyQmzXKYUWPPhSr6NSrCgTf06ZX/WiUXoNBjmWSCB9zYh9euxD5aVedkMDLCBVFSyfx6GbptlJN+hQAf8PyUay7CE36DACr3AT9J5u2yO0wETNcjkcuuEDre3IDTPwyp7OpO3jzv0camABKk6PGz9S3mhBhx5YgGaG8MzYO2Mx0b4qgGMxRDG0cPSqARagiRj2iCfTXlU4tykaqVMtc3BTvF6/xEeVx1PblB9dZypgzLbzW7P84aEkJuJxDLnOlRgQAdPfxfh1DK90xBIibqrQcI2etBDXklHvxVHzW9dxxwDnnN0yrb6bzeCgQgtxDHlqLhVweO0eFlt7hHTuoLqN6hZPOrtOZozxM4/aT5R19qBtwXewFY1gfC1wy6TwQ4rqKDW2U81SvcLw/rdHrNmlZbzVMuH1qWrIuQM7tWnUyVC9nqCvpZqmNkPTOUW2vTBpP6vrKMnOd87TVKTlgJ1SrS2TU31dcYQqAj7tVCnbjmiIeQeW1d5jHmP4ocew6xCFn/SbHUDhYFU/ZsWFdiDAisJv9wNMt299IMB0PD3gB5jSBJXAgW/9WLsvkcROP8CmjQuBAt/zXSaTjNkn6V8HnY3ei9HAicCA75qJ3FkrlhcoB5L0jkpRgg2uYfiI+JewoWXXiVS+auhvxxPWBB1nvh1UrePcmclCxRMw/VgwmqfwguKLaKz5PftEsXqtlnHEeKSeTkX8bQGn7n8bOCb60sBb4sAdt1HuYFVi2KlwI9t1HdW5/fZKgkX6T7RjexvsRvKt0V2p4sXWS2ngVKIlEuyTYP+hROHK9UhYlgaOXGFo6WeZzSZMvaHtdspKAzuFgmiLY+xiie2b22/86rQXTmAGXljG4fnHrBknrGiHDlh49sISXp87YD/XCSv6q4dUt9GA3xkGmpcusYn5KesLN9NhAealMhZ1M3L/wlTjDzdY8X7gwNUaCpUqe3JxyvoUWP/bzECA6dOXWavjF/og8z5dSt/r5dHOMWng0kVxUV4t8SSaTF0nx0AZCMuCTlebkmVj2bb4OcNSji1OWm0p1FWt67ekgX//iZQtO3IgGXVcNvPTjtEzXmqf2qUnf6yJzNjWLhoCfiUPLMCGaVP4Cr/7tyrpLXHZpMi3mY6tcXkgmIZ3YPHLE/k2gxWDQWy34m1LtGSFVFIpt7pBPv0BB0nYYWsTuMMhfe9uerjvLu1QuOnhDof0vbvp4b67tEOhfw9rzNdXpA77nrv+gBmKeFn/y7O1Pgj4AwZeoC/JAwnZNDI8K71wjvM0fwavmd9Iy/R54n8jID8df1hlVwAAAABJRU5ErkJggg==) center no-repeat;
  background-size: contain;
  height: 30px;
  width: 22px;
}
.companyLogo {
  background-size: contain;
  height: 50px;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailInput.email {
  margin-top: 0 !important;
}
.emailInput .inputHeader {
  width: 100% !important;
}
.emailInput .valid > input {
  border-color: #21ba45 !important;
}
.emailInput .noValid > input {
  border-color: #db2828 !important;
}
.emailInput .actions,
.accountUserInfoPassword .actions {
  margin-top: 4px;
}
.emailInput .actions .action {
  cursor: pointer;
  color: #2185d0;
}
.emailInput .actions .action.confirm {
  color: #db2828;
}
.emailInput .actions .action.bold {
  font-weight: bold;
}
.emailInput .actions .save.disabled {
  color: #899ba7;
  cursor: default;
}
.emailInput .emailExists.error {
  color: #db2828;
  font-size: 12px;
}
#tripDetails .revisionDetails {
  color: #889ba7 !important;
}
#tripDetails .revisionDetails > * {
  display: inline-block;
  vertical-align: middle;
}
#tripDetails .revisionDetails img {
  width: 16px !important;
  display: inline-block;
  margin-right: 4px;
}
#tripDetails .revisionDetails .bold {
  font-weight: bold;
  margin-right: 3px;
}
#tripDetails .notesRow {
  margin-bottom: 10px;
  padding-top: 20px;
}
#notesEditor {
  position: relative;
  min-height: 64px;
  outline: none !important;
}
#notesEditor > .readOnlyContent,
#notesEditor > textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  line-height: 1.4;
  /* height: 64px; */
  min-height: 64px !important;
  max-height: 264px;
  border: 1px solid #cacbcd;
  border-radius: 8px;
  padding: 12px !important;
  visibility: visible;
}
#notesEditor > textarea {
  width: 100%;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
#notesEditor > .readOnlyContent {
  overflow: hidden;
  border: 1px solid #dedede;
  outline: none !important;
}
#notesEditor > .readOnlyContent.editable:hover {
  border: 1px solid #cacbcd;
  cursor: pointer;
}
#notesEditor > .readOnlyContent .placeholder {
  color: #aaa;
}
#notesEditor > .readOnlyContent .tag {
  font-weight: bold;
  color: #000;
}
#notesEditor > .readOnlyContent .tag.green {
  color: #24b646;
}
#notesEditor .actions-wrapper {
  position: absolute;
  right: 14px;
  bottom: -33px;
}
#notesEditor .actions-wrapper .action {
  margin: 0px;
  font-size: 0.85714286rem;
}
#notesEditor .actions-wrapper .action.discard {
  margin-right: 8px;
}
#notesEditor .dropdownContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#notesEditor .notesEditorTestArea {
  z-index: 100;
  position: relative;
}
.stepList .two.column.secondCol {
  padding-left: 0 !important;
}
.dateRange {
  position: relative;
}
.DayPicker-Caption > .ui.button {
  margin-right: 0.4em;
  height: 20px;
  padding: 2px 6px;
  min-width: 60px;
}
.removeDateRange {
  position: absolute;
  top: 12px;
  /* top: 4px; */
  right: 7px;
  background-color: grey;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 16px; */
  color: white;
  /* padding-bottom: 16px; */
}
.removeDateRange:hover {
  cursor: pointer;
}
.removeDateRangeSpan {
  margin-top: -3px;
  height: 15px;
  line-height: 14px;
  vertical-align: middle;
}

.tooltipIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s ease;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.4);
  /* box-shadow: 0 0 0 2px rgba(0,0,0, 0.4); */
}
.tooltipIcon.filled {
  background-color: #2185d0;
  border-color: #2185d0;
  box-shadow: none;
  font-weight: 600;
  color: #fff;
}
.tooltipIcon.pulse {
  animation: pulsate 1.8s infinite;
}
.tooltipIcon.pulse.filled {
  animation: none;
}
.tooltip .floatingContent .button {
  float: right;
  margin-top: 12px;
}

.limitWidth {
  max-width: 1280px;
  margin: 0 auto !important;
  /* width: 100%; */
}
#list .table th {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: none;
  height: 39px;
}
#list .dateGrouper {
  border-top: 1px solid rgba(82, 82, 82, 0.4);
  background-color: #fafafa;
  height: 60px;
  padding-bottom: 0px;
}
#list .dateGrouperContainer:first-child .dateGrouper {
  border-top: none;
}

[data-marked='true'] {
  position: relative;
}

[data-marked='true'] * {
  z-index: 1;
}

[data-marked='true']:after {
  content: '';
  height: 105%;
  width: 30px;
  position: absolute;
  right: 4.5px;
  top: -2px;
  background: #2185d0;
  border-radius: 3px;
  z-index: 0;
}

.listHeaderContainer {
  height: 80px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
}
.listHeaderContainer .listHeader {
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listHeaderContainer .listHeader .leftSection,
.listHeaderContainer .listHeader .rightSection {
  /* width: 50%; */
}
.listHeaderContainer .listHeader .rightSection {
  text-align: right;
  float: right;
}
.listHeaderContainer .listHeader .rightSection .newReport {
  height: 40px;
  margin-right: 10px;
  padding: 11px 15px 11px 49px !important;
  font-weight: 400;
}
.listHeaderContainer .listHeader .rightSection.hide {
  visibility: hidden;
}
.listHeaderContainer .listHeader .leftSection,
.listHeaderContainer .listHeader .rightSection,
.listHeaderContainer .listHeader .leftSection > *,
.listHeaderContainer .listHeader .rightSection > *,
.listHeaderContainer .listHeader .leftSection .titleSection > * {
  display: inline-block;
  vertical-align: middle;
}
.listHeader .leftSection span {
  font-weight: normal;
}
.listHeader .leftSection .listTitle {
  margin-right: 40px;
  height: 36px;
  line-height: 36px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.listHeader .leftSection .statusDropdown {
  font-size: 20px;
  height: 36px;
  line-height: 36px;
}
.tripsListContainer .listHeader .leftSection .titleSection {
  margin-right: 30px;
}
.tripsListContainer .breadcrumb .header.link {
  max-width: 260px;
}
.listHeader .leftSection .titleSection > span:first-child {
  font-size: 20px;
}
.listHeader .leftSection .titleSection .label {
  margin-left: 10px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  padding: 0px 6px;
  font-weight: bold;
}
.listHeader .actionsSection.hide {
  visibility: hidden;
}
.listHeader .actionsSection > * {
  margin-right: 10px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #fff;
}
.listHeader .leftSection .marginLeft {
  margin-left: 10px;
}
.listHeader .exportAction,
.listHeader .leftSection .assignAction,
.listHeader .leftSection .classifyAction,
.listHeader .leftSection .deleteAction,
.listHeader .leftSection .resolveTripsAction {
  padding: 11px 15px;
  display: inline-flex;
}
.listHeader .leftSection .deleteAction.isDisabled {
  opacity: 0.45 !important;
  cursor: default;
}
.listHeader .leftSection .deleteAction.isDisabled:hover {
  background-color: #fff;
}
.listHeader .leftSection .resolveTripsAction {
  background-color: #fff !important;
  border: 1px solid #dedede;
  font-weight: normal;
}
.listHeader .leftSection .resolveTripsAction .item:first-child {
  font-weight: bold !important;
}
.listHeader .leftSection .resolveTripsAction .item:last-child {
  color: #db2828 !important;
}
.listHeader .leftSection .classifyAction .dropdown.icon {
  margin-left: 14px !important;
}
.listHeader .exportAction .menu {
  padding: 8px 0;
}
.listHeader .exportAction .menu > .item {
  display: flex;
  align-items: center;
  padding: 8px 14px !important;
}
.listHeader .exportImage {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.listHeader .leftSection .classifyImage,
.listHeader .leftSection .deleteImage,
.listHeader .leftSection .mergeTripImage {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block !important;
}

.listHeader .leftSection .mergeTripImage {
  height: 7px;
  width: 17px;
  margin-bottom: 3px;
}

.listHeader .leftSection .mergeTripButton {
  color: #2185d0;
}

.listHeader .leftSection .mergeTripButton.disabled {
  cursor: default;
  opacity: 0.45 !important;
  background-image: none !important;
  box-shadow: none !important;
  pointer-events: all !important;
}

.listHeader .csvImage,
.listHeader .xlsxImage,
.listHeader .pdfImage {
  width: 14px;
  height: 16px;
}
.listHeader .leftSection .actionsSection > *:last-child {
  margin-right: 0px;
}
.listHeader .leftSection .actionsSection .classifyAction .tagsList {
  border-bottom: 1px solid #dedede;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.listHeader .leftSection .actionsSection .classifyAction .addTagInput > input {
  border: none;
}
.listHeader .leftSection .statusLabel {
  font-size: 20px;
  margin-right: 6px;
  font-weight: bold;
}
.listHeader .leftSection .statusLabel.right {
  margin-right: 40px;
}
.listHeader .leftSection .statusDropdown > .text {
  color: #2185d0;
  font-weight: bold;
  font-size: 20px;
}
.listHeader .leftSection .statusDropdown .dropdown.icon {
  margin-left: 10px;
  color: #2185d0;
}
.listHeader .rightSection .filterSearchContainer {
  position: relative;
  margin-left: 0.5em;
}

.listHeader .rightSection .filterSearch > input {
  width: 240px;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-color: transparent;
  padding-right: 24px !important;
}
.listHeader .rightSection .filterSearchContainer .clearSearch {
  position: absolute;
  right: 4px;
  height: 100%;
  line-height: 40px;
  color: #899ba7;
  cursor: pointer;
  transition: all 0.3s ease;
}
.listHeader .rightSection .filterSearchContainer .clearSearch:hover {
  color: #000;
}
.listHeader .rightSection .dateRangePicker input,
.listHeader .rightSection .dateRangePicker button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.listHeader .rightSection .dateRangePicker input.roundEdge,
.listHeader .rightSection .dateRangePicker .calendarImage.roundEdge,
.listHeader .rightSection .dateRangePicker .button.roundEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.listHeader .actionsSection .submitReportBtn {
  width: 120px;
  background-color: #145a96;
  color: #fff;
}
.listHeader .actionsSection .submitReportBtn.fr,
.listHeader .actionsSection .submitReportBtn.it,
.listHeader .actionsSection .submitReportBtn.de {
  width: 140px;
}
.listHeader .actionsSection .submitReportBtn.isDisabled,
.listHeader .actionsSection .approveAllBtn.isDisabled,
.listHeader .actionsSection .flagBtn.isDisabled {
  opacity: 0.4;
  background-color: #767676;
  cursor: default;
}
.listHeader .actionsSection .submitReportBtn.halfDisabled {
  opacity: 0.4;
  background-color: #767676;
  transition: 0.3s all ease;
}
.listHeader .actionsSection .submitReportBtn.halfDisabled:hover {
  opacity: 0.6;
  background-color: #767676;
}
.listHeader .editReportBtn {
  width: 120px;
  padding: 0;
  height: 36px;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.listHeader .editReportBtn.it,
.listHeader .editReportBtn.fr {
  width: 160px;
}
.listHeader .editReportBtn.es {
  width: 140px;
}
.listHeader .editReportBtn .editIcon {
  width: 13px;
  height: 13px;
  margin-right: 8px;
}
.listHeader .actionsSection .resubmitReportBtn {
  background-color: #145a96;
  color: #fff;
}
.listHeader .actionsSection .resubmitReportBtn.isDisabled {
  background-color: #767676;
  opacity: 0.4;
  font-weight: 400;
}

.reportTripsListContainer .listHeader .filterSearch > input {
  border-radius: 4px;
  border-right-color: rgba(34, 36, 38, 0.15);
}
.reportRulesListContainer .listHeader .leftSection .nameHeader {
  display: block;
  font-size: 20px;
  margin-bottom: 4px;
}
.reportRulesListContainer .listHeader .leftSection .subText {
  display: block;
  font-size: 14px;
  line-height: 1.29;
  color: #899ba7;
}
.reportRulesListContainer .breadcrumb .header {
  max-width: 300px;
}

.reportTripsListContainer .listHeader .approveAllBtn {
  background-color: #007d02;
  color: #fff;
}
.reportTripsListContainer .listHeader .flagBtn {
  background-color: #db2828;
  color: #fff;
}

.searchFliterTooltip {
  width: 260px;
}
.searchFliterTooltip .list .item {
  margin: 3px 0;
}
.searchFliterTooltip .list .item span {
  display: inline-block;
  vertical-align: middle;
}
.searchFliterTooltip .grey {
  color: #899ba7;
  margin: 0 3px;
}
.searchFliterTooltip .grey.noMargin {
  color: #899ba7;
  margin: 0;
}
.classifyTrigger,
.downloadTrigger {
  display: flex;
}
.classifyAction .loaderContainer,
.downloadTrigger .loaderContainer {
  position: relative;
  width: 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.newTripBtnWrapper {
  margin-right: 15px;
}

.newTripBtnWrapper.disabled button {
  opacity: 0.45;
  cursor: default;
}

/* tablet */
@media only screen and (max-width: 991px) {
  #menu {
    padding: 0 25px;
    /* padding: 0 20px; */
  }
  #psngrWebApp .filterBar {
    margin: 0;
  }
  #psngrWebApp .filterBar > .group {
    margin-bottom: 12px;
  }
  #psngrWebApp .filterBar > .group:last-child {
    margin-bottom: 0px;
  }
  .filterBarContainer {
    margin-left: -20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

/* mobile */
@media only screen and (max-width: 767px) {
  #menu {
    padding: 0;
  }
  /* trips list */
  .tripsListContainer .footer {
    padding: 20px;
    height: 160px;
  }
  .tripsListContainer .footer > .right {
    margin-top: 0px;
  }
  .tripsListContainer .footer > .right > * {
    display: block;
    margin: 0px 0px 6px 0px;
  }
  .tripsListContainer .footer > .right > .button.delete {
    float: right;
  }
  .tripsListContainer .footer > .right > *:last-child {
    margin-bottom: 0px;
  }
  .listHeaderContainer .listHeader {
    padding: 0 20px;
  }
  /* trips list */
  #list .table th {
    height: 21px;
  }
  #table-body .dateGrouperContainer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #table-body .dateGrouper {
    width: 100% !important;
    display: flex !important;
    align-items: center;
  }
}

@media only screen and (max-width: 450px) {
  .ui.input.inputDateRange,
  .dropdown.button.withSelection.tags {
    margin-bottom: 12px;
  }
  .filterBarContainer {
    margin-left: -10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}

@keyframes fadeInEntry {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
body.dashboard {
  font-family: 'Lato', sans-serif;
}
* {
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 3px !important;
}
#navigation {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  /* border-bottom: 2px solid rgba(34, 36, 38, 0.15); */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 50px;
}

#page {
  position: absolute;
  width: 100%;
  top: 50px;
  height: calc(100% - 50px);
  overflow: auto;
  transition: all 0.4s ease;
}
#page.public {
  top: 0px;
  height: 100%;
  position: relative;
  padding: 0px;
  margin-top: 100px;
}

#page.listPageContainer {
  overflow: hidden;
  padding: 0px;
}
#page.notifBarShowing {
  top: 86px;
  height: calc(100% - 86px);
}
#page.isApp {
  top: 0;
  height: 100%;
}

#notifCenter {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
}
#notifCenter .animated {
  animation-duration: 0.5s;
  margin-bottom: 8px;
}
.ui.labeled.icon.button,
.ui.labeled.icon.buttons .button {
  width: auto;
}

#addressForm .ui.dropdown > .text,
#account .ui.dropdown > .text {
  max-width: calc(100% - 24px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#addressForm .searchDropdown.dropdown {
  border: 1px solid #979797;
}

#exportTripsWarning {
  width: 400px;
}
#exportTripsWarning .header {
  padding: 25px 40px 12px !important;
  font-size: 20px;
  color: #2185d0;
}
#exportTripsWarning .content {
  padding: 20px 40px !important;
  font-size: 14px;
  line-height: 1.43;
}
#exportTripsWarning .content .bold {
  font-weight: 600;
}
#exportTripsWarning .actions {
  padding: 14px 40px !important;
}

/* smooch iframe */
#web-messenger-container {
  bottom: 20px;
  right: 14px;
}

#errorTooltip {
  background-color: #db2828;
  color: #fff;
}
#errorTooltip.ui.popup:before {
  background-color: #db2828;
  box-shadow: 1px 1px 0 0 #db2828;
}

.unit.currency > .symbol {
  margin-right: 2px;
}

@media screen and (max-width: 991px) {
  #page {
    padding: 0px 20px;
  }
}
@media screen and (max-width: 768px) {
  #page::-webkit-scrollbar {
    width: 3px;
  }
}
@media screen and (max-width: 368px) {
  #page {
    padding: 0px 10px;
  }
}

#mobile-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 20px; */
  height: 50px;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  background-color: #ffffff;
}
#mobile-menu .mobileMenuLeft {
  display: flex;
  align-items: center;
}
#mobile-menu .mobileMenuRight {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
}

#mobile-menu i.icon {
  margin: 0;
  text-align: right;
}

/* Sidebar START */
.mobileSidebar,
.dimmedSidebarBg {
  height: calc(100vh - 50px);
  top: 50px;
  left: 0px;
  position: fixed;
}
.dimmedSidebarBg {
  width: 100vw;
  z-index: 99;
  filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
}
.dimmedSidebarBg.visible {
  display: block;
}
.mobileSidebar {
  width: 85vw;
  position: fixed; /* Stay in place */
  z-index: 101; /* Stay on top */
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  margin-left: -100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobileSidebar.open {
  margin-left: 0;
}

.mobileSidebar .footer {
  padding: 20px;
  height: auto;
}
.mobileSidebar .footer .langItem {
  margin-right: 16px;
}
.mobileSidebar .footer .langItem:last-child {
  margin-right: 0px;
}
.mobileSidebar .footer .langItem.selected {
  background-color: transparent;
  text-decoration: underline;
  color: #24b646;
  font-weight: bold;
}
.sidebarMenuHeaderItem {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(222, 222, 222, 0.4);
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.sidebarMenuItem {
  border-bottom: 1px solid rgba(222, 222, 222, 0.4);
  height: 52px;
  /* height: calc((100vh - 50px) / 9); */
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.25;
  cursor: pointer;
}
.sidebarMenuItem:last-child {
  border-bottom: none;
}
.sidebarMenuItem[item-key='signout'] {
  color: #db2828;
}
.sidebarMenuItem.active {
  font-weight: bold;
}
.sidebarMenuItem > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100%;
  height: 100%;
}
.sidebarLabel {
  height: 20px;
  border-radius: 4px;
  background-color: #db2828;
  padding: 0 7px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
/* Sidebar END */

/* Teambar START */
.mobileTeamBar {
  width: 100vw;
  position: fixed; /* Stay in place */
  z-index: 99; /* Stay on top */
  top: 50px; /* Stay at the top */
  left: 0;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: -100vh;
}
.mobileTeamBar.open {
  margin-top: 0;
}
.teamMenuItem {
  border-bottom: 1px solid rgba(222, 222, 222, 0.4);
  height: 52px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.25;
  cursor: pointer;
}
.teamMenuItem:last-child {
  border-bottom: none;
}
.teamMenuItem.active {
  background-color: rgba(222, 222, 222, 0.4);
  font-weight: bold;
}
.dimmedTeambarBg {
  height: calc(100vh - 50px);
  width: 100vw;
  position: fixed;
  z-index: 98;
  top: 50px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}
.dimmedTeambarBg.visible {
  display: block;
}
#landingHeader .mobileSidebar,
#landingHeader .dimmedSidebarBg,
#landingHeader .dimmedTeambarBg {
  height: calc(100vh - 72px);
  top: 72px;
}

/* Teambar END */

