.introSection {
  display: flex;
  margin: 80px auto 0;
  min-height: 727px;
  width: 100%;
  max-width: 1280px;
  padding-left: 80px;
  position: relative;
}
.introSection .left {
  width: 56%;
  display: flex;
  position: relative;
  z-index: 10;
}
.pro .introSection .left {
  width: 50%;
}
.enterprise .introSection .left {
  width: 50%;
}
.introSection .right {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1331px;
  display: flex;
  margin-bottom: 72px;
  transform: translateY(-50px);
}
.introSection .details {
  margin-top: 90px;
  padding-right: 20px;
}
.introSection .details .appName {
  font-size: 30px;
  color: #24b646;
  font-weight: bold;
  line-height: 1.33;
}
.introSection .details .title {
  font-size: 54px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 30px;
  line-height: 1.22;
  padding-right: 16px;
}
.introSection .details .subTitle {
  color: #666666;
  font-size: 20px;
  line-height: 1.6;
  max-width: 430px;
}
.introSection .details .actions {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
}
.introSection .actions .action {
  margin: 5px 20px 5px 0;
}
.introSection .actions .action:last-child {
  margin-right: 0;
}
.introSection .actions .action.appStoreLink {
  width: 150px;
  height: 70px;
}
.introSection .actions .action.playStoreLink {
  height: 66px;
  width: 171px;
  margin-top: 7px;
}
.introSection .actions .action > img {
  width: 100%;
  height: 100%;
}
.introSection .right .dashboardScreen {
  width: 100%;
  background-image: url('/images/landing/dashboardScreenshot.png');
  background-repeat: no-repeat;
  background-size: 810px 549px;
  background-position: bottom left;
}
.introSection .right .appScreen {
  width: 237px;
  height: 414px;
  object-fit: contain;
  background-image: url('/images/landing/appScreenshot.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
/* .introVideo {
  width: 1280px;
  height: 640px;
} */

/* .react-player {
  height: 640px;
} */

/* Landing Pro START */
.pro .introSection .details {
  padding-bottom: 130px;
}
.pro .introSection .right .dashboardScreen {
  background-image: url('/images/landing/car.png');
  /* background-size: 1624px 557px; */
  background-size: 1331px 570px;
  display: flex;
  /* background-position-x: -40px;
  background-position-y: 140px; */
}
.pro .introSection .right .appScreen {
  width: 320px;
  height: 560px;
  bottom: 10px;
  /* left: 250px; */
}
.pro .introSection .right .playButton {
  background-image: url('/images/landing/btn_play.svg');
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  object-fit: contain;
  width: 120px;
  height: 90px;
}

.pro .introSection .details .actions .upgradeBtn,
.enterprise .introSection .details .actions .upgradeBtn {
  width: 300px;
  height: 60px;
  border-radius: 30px;
  background-color: #24b646;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  outline: none;
  line-height: 60px;
  display: block;
}

/* Landing Pro END */

/* Landing Enterprise START */

.enterprise .introSection .right .dashboardScreen {
  background-image: url('/images/landing/dashboardScreenshot_reports.png');
}
.enterprise .introSection .details .actions {
  flex-direction: column;
}
.enterprise .introSection .details .actions .showVideoAction {
  color: #00b932 !important;
  font-size: 18px !important;
  font-weight: 500;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}
.enterprise .introSection .details .actions .showVideoAction > * {
  display: inline-block;
  vertical-align: middle;
}
.enterprise .introSection .details .actions .showVideoAction > .image {
  width: 40px;
  margin-right: 12px;
}
#seeInActionModal {
  margin-top: 0px !important;
}
#seeInActionModal .content {
  background-color: #000;
}

/* Landing Enterprise END */

@media screen and (max-width: 1280px) {
  .introSection .details .title {
    font-size: 44px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #333333;
  }

  .pro .introSection .right .playButton {
    margin: auto auto auto 30%;
  }
}
@media screen and (max-width: 1024px) {
  .introSection {
    min-height: 540px;
    padding: 0 40px;
  }
  .introSection .left {
    width: 55%;
    display: flex;
    position: relative;
  }
  .introSection .right {
    left: 55%;
    width: 1000px;
    display: flex;
  }
  .introSection .details .title {
    font-size: 38px;
  }
  .introSection .right .dashboardScreen {
    background-size: 583px 395px;
    /* background-size: 672px 455px; */
  }
  .introSection .right .appScreen {
    width: 171px;
    height: 298px;
    left: 10px;
  }

  /* Landing Pro START */

  .pro .introSection .right .dashboardScreen {
    background-image: url('/images/landing/car.png');
    /* background-size: 1624px 557px; */
    background-size: 887px 380px;
    /* background-position: -70px 115px; */
  }
  .pro .introSection .right .appScreen {
    width: 229px;
    height: 400px;
    bottom: 10px;
    /* left: 72px; */
  }

  .pro .introSection .right .playButton {
    margin: auto auto auto 23%;
  }

  /* Landing Pro END */
}
@media screen and (max-width: 767px) {
  .introSection {
    flex-direction: column;
    padding: 0;
    margin-top: 72px;
  }
  .introSection .left {
    width: 100% !important;
    padding: 0 30px;
  }
  .introSection .right {
    position: relative;
    left: 0;
    height: 230px;
    width: 331px;
    margin: 40px auto 0;
    transform: none !important;
  }
  .introSection .details {
    margin: 30px auto 0 !important;
  }
  .introSection .details .appName {
    font-size: 20px;
    color: #24b646;
    font-weight: bold;
  }
  .introSection .details .title {
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .introSection .details .subTitle {
    color: #666666;
    font-size: 18px;
    line-height: 1.44;
  }
  .introSection .details .actions {
    margin-top: 40px;
    justify-content: center;
  }
  .introSection .right .dashboardScreen {
    width: 100%;
    background-size: 331px 221px;
  }
  .introSection .right .appScreen {
    width: 95px;
    height: 167px;
    left: 20px;
  }

  /* Landing Pro START */
  .pro .introSection .details {
    padding-bottom: 0;
  }
  .pro .introSection .right {
    position: relative;
    left: 0;
    height: 230px;
    /* margin-top: 40px; */
    width: 100% !important;
    margin: 0px auto;
    height: 400px;
  }
  .pro .introSection .right .dashboardScreen {
    /* background-size: 140vw 289px; */
    background-size: 762px 309px;
    background-position: 90px;
    width: 110vw;
    height: 310px;
    transform: translateY(140px);
  }
  .pro .introSection .right .appScreen {
    width: 200px;
    height: 354px;
    bottom: 0px;
    left: calc(20px);
  }

  .pro .introSection .right .playButton {
    margin: auto 10% auto auto;
  }

  .pro .introSection .details .actions .upgradeBtn,
  .enterprise .introSection .details .actions .upgradeBtn {
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #24b646;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    outline: none;
    line-height: 60px;
    margin: 0;
  }

  /* Landing Pro END */
}

@media screen and (max-width: 525px) {
  .pro .introSection .right .dashboardScreen {
    background-position: -40px;
  }
}
