#landingPage .plansSection {
  margin-top: 100px;
}
.plansSection .section .titleContainer {
  /* padding-left: 36px; */
}
.plansSection .plansContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.home .plansSection .plansContainer {
  max-width: 1120px;
  margin: 60px auto 0;
}
.plansSection .plansContainer .planContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 0 3px 0 rgba(60, 145, 204, 0.17);
  border: 1px solid rgba(60, 145, 204, 0.3);
  color: #4a4a4a;
  transition: all 0.3s ease;
  background-color: #ffffff;
}
.home .plansSection .plansContainer .planContainer {
  width: 32%;
  max-width: 340px;
  height: 420px;
  padding: 50px 20px 30px;
}
.enterprise .plansSection .plansContainer .planContainer {
  width: 30%;
  max-width: 360px;
  min-width: 210px;
  height: 480px;
  padding: 70px 20px 30px;
}
.plansSection .plansContainer .planContainer:hover,
.plansSection .plansContainer .planContainer:focus {
  /* transform: translateY(-30px);
  box-shadow: 0 10px 30px 0 rgba(60, 145, 204, 0.17); */
}
.plansSection .planContainer .top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plansSection .planContainer .bottom {
  width: 100%;
}
.plansSection .planContainer .bottom .actionsContainer {
  height: 50px;
  margin-top: 20px;
}
.plansSection .plansContainer .planContainer > .image {
  height: 70px;
  width: auto;
}
.home .plansSection .plansContainer .planContainer .plan {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 600;
}
.plansSection .plansContainer .planContainer .features {
  line-height: 1.63;
  font-size: 16px;
}
.plansSection .plansContainer .planContainer .features .feature > span {
  margin-left: 4px;
}
.plansSection
  .plansContainer
  .planContainer
  .features
  .feature
  > span:first-child {
  margin-left: 0px;
}
.plansSection .plansContainer .planContainer .features .feature > span.bold {
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .planPrice {
  color: #24b646;
  font-size: 22px;
  font-weight: bold;
}
.plansSection
  .plansContainer
  .planContainer.enterprise
  .planPrice
  .priceContainer {
  display: inline-block;
}
.plansContainer .planContainer .planPrice .priceContainer .perMonth {
  font-weight: normal;
  margin-left: 12px;
}

.plansContainer .planContainer .planPrice .priceContainer .currency {
  font-weight: normal;
}
.plansSection .plansContainer .planContainer .planPrice .currency {
  top: -36px;
  margin-right: 8px;
  display: inline-flex;
  transform: translateY(-40px);
}
.plansSection .plansContainer .planContainer .planPrice .price {
  font-size: 72px;
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .decimalPoint,
.plansSection .plansContainer .planContainer .decimal {
  font-size: 36px;
}
.plansSection .plansContainer .planContainer .frequency {
  margin-top: 6px;
  line-height: 1.71;
  opacity: 0.7;
}
.plansSection .plansContainer .planContainer .frequency > span {
  margin-left: 4px;
}
.plansSection .plansContainer .planContainer .frequency > span.bold {
  font-weight: bold;
}
.plansSection .plansContainer .planContainer .action {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border-radius: 25px;
  background-color: #24b646;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 0px auto;
}

.enterprise .plansSection {
  margin-top: 140px;
}
.enterprise .plansSection .section {
  padding: 0px;
}
.enterprise .plansSection .plansContainer .planContainer .plan {
  margin: 36px 0px 20px;
  font-size: 36px;
  font-weight: 600;
  line-height: 0.94;
}
.plansSection .plansContainer .planContainer .employeeCount {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}
.plansSection .plansContainer .planContainer .employeeCount .bold {
  font-weight: bold;
  margin-right: 5px;
}
.plansSection .plansContainer .planContainer .addOnInfo {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.71;
  color: #4a4a4a;
  opacity: 0.7;
}
.plansSection .plansContainer .planContainer.custom .addOnInfo {
  margin-top: 6px;
}
.plansSection .plansContainer .planContainer .addOnInfo .inline,
.plansSection .plansContainer .planContainer .addOnInfo .currency {
  margin-right: 5px;
}
.enterprise .plansSection .plansContainer .planContainer.custom .planPrice {
  font-size: 36px;
  width: 210px;
  line-height: initial;
  margin: 40px auto 0px;
}
.plansSection .plansContainer .planContainer .image.Family {
  width: 72px;
  height: 35px;
}
.plansSection .plansContainer .planContainer .image.Business {
  width: 152px;
  height: 37px;
}
.plansSection .plansContainer .planContainer .image.Platinum {
  width: 237px;
  height: 46px;
}

/* TABLET */
@media screen and (max-width: 1024px) {
  .plansSection .section .titleContainer {
    padding: 0px;
    /* margin-left: 120px; */
  }
  .plansSection .plansContainer {
    margin-bottom: 40px;
  }
  .home .plansSection .plansContainer {
    margin-top: 40px;
  }
  .home .plansSection .plansContainer .planContainer {
    height: 330px;
    padding: 30px 14px;
    width: 30%;
  }
  .plansSection .plansContainer .planContainer .features {
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
  }
  .plansSection .plansContainer .planContainer .planPrice .price {
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
  }
  .plansSection .plansContainer .planContainer .decimalPoint,
  .plansSection .plansContainer .planContainer .decimal {
    font-size: 24px;
    color: #24b646;
    font-weight: bold;
  }
  .plansSection .plansContainer .planContainer .planPrice .currency {
    transform: translateY(-20px);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
  }
  .plansSection .plansContainer .planContainer .planPrice .perMonth {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    margin-left: 12px;
  }
  .plansSection .plansContainer .planContainer.enterprise .planPrice > span {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
  }
  .plansSection .plansContainer .planContainer .actionsContainer,
  .plansSection .plansContainer .planContainer .action {
    width: 100%;
    height: 40px !important;
  }
  .plansSection .plansContainer .planContainer .action {
    max-width: 180px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
  }
  .plansSection .plansContainer .enterprise .frequency span:last-child {
    display: none;
  }
  .enterprise .plansSection .plansContainer .planContainer {
    height: 300px;
    padding: 30px 20px;
  }
  .plansSection .plansContainer .planContainer .image {
    display: none;
  }
  .enterprise .plansSection .plansContainer .planContainer .plan {
    margin: 0 0 10px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
  }
  .enterprise .plansSection .plansContainer .planContainer.custom .planPrice {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    max-width: 120px;
  }
  .plansSection .plansContainer .planContainer .employeeCount {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .plansSection .plansContainer .planContainer .employeeCount .bold {
    font-weight: bold;
    margin-right: 5px;
  }
  .plansSection .plansContainer .planContainer .addOnInfo {
    margin-top: 0;
  }
}

/* MOBILE */
@media screen and (max-width: 767px) {
  #landingPage .plansSection {
    margin-bottom: 60px;
  }
  .plansSection .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .plansSection .section .titleContainer {
    margin: 0px;
  }
  .plansSection .plansContainer {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
  }
  .plansSection .plansContainer .priceWrapper .price,
  .plansSection .plansContainer .priceContainer {
    color: #24b646;
  }
  .plansSection .plansContainer .planContainer {
    width: 100% !important;
    min-width: 260px;
    margin-bottom: 20px;
    padding: 20px 16px !important;
    height: auto !important;
    display: flex;
    flex-direction: row;
  }
  .home .plansSection .plansContainer .planContainer {
    align-items: flex-start;
  }
  .plansSection .plansContainer .planContainer:last-child {
    margin-bottom: 0px;
  }
  .plansSection .plansContainer .planContainer .bold {
    font-weight: 600;
  }
  .plansSection .plansContainer .planContainer .planName {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    text-align: left;
  }
  .plansSection .plansContainer .planContainer .features {
    text-align: left;
    font-size: 12px;
    line-height: 1.33;
    margin-top: 10px;
  }
  .plansSection .plansContainer .planContainer .features span {
    margin-right: 4px;
  }
  .plansSection .plansContainer .planContainer .priceWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .perMonth {
    font-weight: normal;
    margin-left: 8px;
  }
  .plansSection .plansContainer .planContainer .frequency {
    font-size: 12px;
    line-height: 1.33;
    margin-top: 4px;
  }
  .plansSection .plansContainer .planContainer .price {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    /* display: flex; */
    justify-content: flex-end;
  }
  .plansSection .plansContainer .planContainer .decimalPoint,
  .plansSection .plansContainer .planContainer .decimal {
    font-size: 20px;
    color: #24b646;
    font-weight: bold;
  }
  .plansSection .plansContainer .planContainer .currency {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    margin-right: 6px;
  }
  .plansSection .plansContainer .planContainer .action {
    width: 200px;
  }
  .plansSection .sectionFooter {
    max-width: 100%;
    font-size: 16px;
  }
}
