#page .helpPageContainer {
  margin-top: 0px;
}
.helpPageContainer {
  margin-top: 80px;
  height: calc(100% - 80px);
}
.helpPageContainer .helpPageHeaderContainer .pageName {
  font-size: 20px;
}
.helpPageContainer .helpPageHeaderContainer .helpButtonMail {
  display: none !important;
}
#page .helpPageContainer .content {
  height: 100%;
}
.helpPageContainer .content {
  max-width: 1280px;
  height: calc(100vh - 160px);
  margin: 0 auto;
  padding: 40px 50px 0px;
  display: flex;
  justify-content: space-between;
}
.helpPageContainer .searchListContainer {
  width: 700px;
}
.searchListContainer .searchInput input {
  height: 50px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 0.88;
}
.searchListContainer .itemsListContainer {
  margin-top: 20px;
  max-height: calc(100% - 70px);
  overflow: auto;
  overflow-x: hidden;
}
.itemsListContainer .itemContainer .question {
  background-color: #fafafa;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.itemsListContainer .itemContainer .question > .icon {
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.5);
}
.itemsListContainer .itemContainer .question > .icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.itemsListContainer .itemContainer .answer {
  padding: 20px 30px 40px;
  font-size: 14px;
  line-height: 1.5;
}

.itemsListContainer .itemContainer .answer li {
  margin: 0 0 3px 0;
}

.supportSegment {
  max-width: 390px;
}
.supportSection .supportButtons {
  display: flex;
  justify-content: space-between;
}
.helpButtonIcon {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white;
}
.helpButtonIconTwitter {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: white;
}
.helpButton {
  height: 36px;
  width: 48%;
  border-radius: 4px !important;
  margin-bottom: 34px !important;
  font-weight: normal !important;
  font-size: 13px !important;
  white-space: nowrap;
}
.helpButtonTwitter {
  background: #00aced !important;
  color: white !important;
}
.helpButtonMail {
  background: #21ba45 !important;
  color: white !important;
}
.to-learn-more-about {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.to-learn-more-about .text-style-1 {
  color: #2185d0;
}
.findOut {
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.3px;
  color: #000000;
  border-radius: 4px;
  background-color: #f9fafb;
  padding: 8px 20px;
  max-width: 390px;
}
.findOut > p > a {
  font-weight: 600;
}
.segment-mobile {
  margin: 10px !important;
}
.psngrSupport {
  height: 27px;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.dont-want-to-search {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

@media only screen and (max-width: 1280px) {
  .helpPageContainer .content {
    justify-content: center;
  }
  .helpPageContainer .supportSection {
    display: none;
  }
  .helpPageContainer .helpPageHeaderContainer .helpButtonMail {
    display: block !important;
    margin: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .helpPageContainer .searchListContainer {
    height: 100%;
  }
  .helpPageContainer .supportSection {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

/* tablet */
@media only screen and (max-width: 991px) {
  .supportSegment {
    max-width: 300px;
  }
}

/* iPad */
@media only screen and (max-width: 768px) {
  .helpPageContainer .content {
    padding: 40px 20px 0px;
  }
  .helpPageContainer .searchListContainer {
    width: 100%;
  }
  .supportSegment {
    width: 100%;
    max-width: 400px;
  }
  .supportSection {
    max-width: 100% !important;
  }
}
