.statsSection {
  background-color: #f5f7fa;
  height: 380px;
  margin-top: -50px;
}
.statsSection .section {
  display: flex;
  justify-content: space-around;
  height: 100%;
}
.statsSection .distanceStats,
.statsSection .ratingStats,
.statsSection .awardsStats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statsSection .awardImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: bottom;
  gap: 24px;
}
.statsSection .distanceStats .distance.unit {
  line-height: 1.09;
  color: #24b646;
  font-size: 24px;
  font-weight: 800;
}
.statsSection .distanceStats .distance.unit .quantity {
  font-size: 44px;
}
.statsSection .distanceStats .distance.unit .unit {
  margin-left: 6px;
}
.statsSection .distanceStats .text {
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  line-height: 1.36;
}
.statsSection .ratingStats {
  width: 460px;
}
.statsSection .ratingStats .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.statsSection .ratingStats .rating .ratingText {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
}
.statsSection .ratingStats .ratingStars .star {
  display: inline-block;
  background: url('/images/star.png') center no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.statsSection .ratingStats .ratingStars .star:last-child {
  margin-right: 0px;
}
.statsSection .ratingStats .ratingStars .star.half {
  background-image: url('/images/half-star.png');
}
.statsSection .ratingStats .testimonial .box {
  width: 100%;
  padding: 20px 30px;
  background-color: #24b646;
  opacity: 0.8;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.56;
  border-radius: 8px;
  position: relative;
}
.statsSection .ratingStats .testimonial .box.pointed::after {
  content: '';
  width: 0;
  height: 0;
  border-bottom: 25px solid #24b646;
  border-right: 25px solid transparent;
  position: absolute;
  bottom: -8px;
  right: 30px;
  transform: rotate(-30deg);
  opacity: 0.9;
}
.statsSection .ratingStats .testimonial .author {
  font-size: 14px;
  color: #4a4a4a;
  text-align: right;
  margin-right: 70px;
  margin-top: 4px;
}
.statsSection .awardsStats .awardImg {
  width: 82px;
  height: 79px;
}
.statsSection .awardsStats .text {
  color: #4a4a4a;
  font-size: 22px;
  line-height: 1.36;
  text-align: center;
  margin-top: 14px;
  max-width: 280px;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 38px;
  }
  .statsSection .ratingStats {
    width: 400px;
  }
  .statsSection .ratingStats .testimonial .box {
    padding: 20px 16px;
  }
  .statsSection .awardsStats .text,
  .statsSection .distanceStats .text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .statsSection {
    height: 530px;
    margin-top: 45px;
  }
  .statsSection .section {
    display: none !important;
  }
  #landingPage .statsSection .section.mobile {
    display: flex !important;
    flex-direction: column;
  }
  .statsSection .section.mobile .firstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .statsSection .section.mobile .secondRow {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .statsSection {
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
    margin-top: -20px;
  }
  .statsSection .section.mobile .firstRow {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .statsSection .distanceStats {
    margin-bottom: 40px;
  }
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 34px;
  }
  .statsSection .distanceStats .distance.unit .unit {
    font-size: 18px;
  }
  .statsSection .distanceStats .text {
    font-size: 18px;
    margin-top: 10px;
  }

  .statsSection .awardsStats .text {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    margin-top: 10px;
  }

  .statsSection .ratingStats .rating {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .statsSection .ratingStats .rating .ratingStars {
    margin-bottom: 12px;
  }
  .statsSection .ratingStats .rating .ratingStars .star {
    height: 20px;
    width: 20px;
    background-size: cover;
  }
  .statsSection .ratingStats .rating .ratingText {
    margin-left: 0;
  }
  .statsSection .ratingStats .testimonial .box {
    font-size: 14px;
    font-weight: 300;
    height: 145px;
  }
  .statsSection .ratingStats .testimonial .box > div {
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 350px) {
  .statsSection .distanceStats .distance.unit {
    font-size: 18px !important;
  }
  .statsSection .distanceStats .distance.unit .quantity {
    font-size: 34px !important;
  }
}
