#landingPage .beaconIntroSection {
  margin-top: 100px;
  margin-bottom: 100px;
}
.beaconIntroSection .video {
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  width: 100%;
  max-width: 960px;
  height: 432px;
}
.beaconIntroSection .video .videoPlayer {
  width: 100% !important;
  height: 100% !important;
}
.beaconIntroSection .video .beaconVideoPreview {
  background-image: url('/images/landing/beacon_video_thumbnail.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0px;
}

@media screen and (max-width: 1024px) {
  #landingPage .beaconIntroSection {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .beaconIntroSection .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .beaconIntroSection .section .titleContainer {
    /* margin-left: 120px; */
  }
  .beaconIntroSection .video {
    margin-top: 40px;
    height: 383px;
  }
}

@media screen and (max-width: 767px) {
  #landingPage .beaconIntroSection {
    margin: 80px 0;
  }
  .beaconIntroSection .section {
    padding: 0px !important;
    align-items: flex-start;
  }
  .beaconIntroSection .section .titleContainer {
    margin-left: 0px;
    /* padding: 0px 30px; */
  }
  .beaconIntroSection .video {
    height: 280px;
    margin-top: 40px;
    margin-bottom: 0;
  }
}
