.carouselContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.carouselContainer .carousel {
  width: 100%;
  height: 100%;
}
.carouselContainer .list {
  display: inline-flex;
  transition: 0.4s ease-out all;
}
.carouselContainer .navBtn {
  position: absolute;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  text-align: center;
}
.carouselContainer .navBtn.prevNavBtn {
  left: 0;
}
.carouselContainer .navBtn.nextNavBtn {
  right: 0;
}
.carouselContainer .navBtn:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.carouselContainer .navBtn.hide {
  color: #aaa;
  pointer-events: none;
}
.carouselContainer .navBtn > .icon {
  text-align: center !important;
  cursor: pointer;
  margin: 0px !important;
}
