.section {
}

.section .sectionTitle {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.27;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section .sectionTitle.white {
  color: #ffffff;
}
.section .sectionTitle .secondLine {
  display: flex;
  align-items: center;
}
.section .sectionTitle .secondLine .dash {
  width: 100px;
  height: 6px;
  background-color: #4a4a4a;
  margin-right: 20px;
}
.section .sectionTitle.white .secondLine .dash {
  background-color: #ffffff;
}
.section .sectionTitle.white .dash {
  background-color: #ffffff;
}

/* Upgrade Pro Section START */

.upgradeProSection {
  background: url('/images/landing-pro-upgrade-background.png') center no-repeat;
  background-size: cover;
  height: 520px;
  margin-bottom: 40px;
}
.upgradeProSection.mobile {
  height: auto;
}
.upgradeProSection .section {
  display: flex;
  justify-content: space-between;
}
.upgradeProSection .left {
  height: 100%;
  padding-top: 80px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.upgradeProSection .left .perksContainer {
  max-width: 480px;
  margin-left: 80px;
  margin-top: 40px;
}
.upgradeProSection .left .perksInfo {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 26px;
}
.upgradeProSection .left .perksList {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
  padding-left: 20px;
}
.upgradeProSection .left .perksList li {
  margin: 13px 0;
}
.upgradeProSection .left .perksList li:first-child {
  margin-top: 0;
}
.upgradeProSection .left .perksList li:last-child {
  margin-bottom: 0;
}

#landingPage .upgradeProSection.mobile .section {
  flex-direction: column;
  padding-top: 60px !important;
  padding-bottom: 30px !important;
}
.upgradeProSection.mobile .titleWrapper .sectionSubTitle {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
}
.upgradeProSection.mobile .plansContainer {
  margin-top: 40px;
}
.upgradeProSection.mobile .plansContainer .planContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0 3px 0 rgba(60, 145, 204, 0.17);
  border: solid 1px rgba(60, 145, 204, 0.3);
  background-color: #ffffff;
  padding: 16px;
  color: #4a4a4a;
}
.upgradeProSection.mobile .plansContainer .planContainer:first-child {
  margin-bottom: 10px;
}
.upgradeProSection.mobile .plansContainer .planContainer .planName {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
}
.upgradeProSection.mobile .plansContainer .planContainer .frequency {
  line-height: 1.33;
  font-size: 12px;
  color: #9b9b9b;
}
.upgradeProSection.mobile .plansContainer .planContainer .details .frequency {
  font-weight: 500;
  margin-top: 10px;
}
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .frequency {
  text-align: right;
}
.upgradeProSection.mobile .plansContainer .planContainer .priceWrapper {
  color: #24b646;
}
.upgradeProSection.mobile .plansContainer .planContainer .priceWrapper .price {
  text-align: right;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.11;
  /* display: flex; */
  justify-content: flex-end;
  margin-left: 4px;
}
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .decimalPoint,
.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .decimal {
  font-size: 20px;
  font-weight: bold;
}

.upgradeProSection.mobile
  .plansContainer
  .planContainer
  .priceWrapper
  .price
  > .currency {
  font-size: 28px;
  line-height: 1.57;
  font-weight: normal;
  margin-right: 4px;
}

.upgradeCard {
  width: 360px;
  height: 480px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(170, 220, 255, 0.6);
  background-color: #ffffff;
  padding: 80px 48px 80px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  margin-right: 40px;
}
.upgradeCard .image {
  width: 90px;
  height: 62.3px;
  margin-bottom: 14px;
}
.upgradeCard .priceContainer {
  height: 70px;
  display: flex;
  align-items: flex-end;
}
.upgradeCard .priceContainer span {
  color: #24b646;
}
.upgradeCard .priceContainer .currency {
  align-self: flex-start;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
.upgradeCard .priceContainer .price {
  font-size: 80px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: normal;
  text-align: right;
}
.upgradeCard .priceContainer .perMonth {
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin-left: 12px;
}
.upgradeCard .switchContainer {
  display: flex;
  align-items: center;
}
.upgradeCard .switchContainer .switch {
  padding: 0 14px;
}
.upgradeCard .switchContainer .switchLabel {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #9b9b9b;
}
.upgradeCard .switchContainer .switchLabel.active {
  color: #4a4a4a;
  font-weight: bold;
}

.upgradeCard .actions {
  width: 100%;
}
.upgradeCard .actions .upgradeBtn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #24b646;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

/* Upgrade Pro Section END */

/* Questions Section START */

.questionsSection {
  margin-top: 120px;
}
.questionsSection .questionsContainer {
  height: 240px;
  width: 100%;
  background-color: #f5f7fa;
  padding: 64px 120px 64px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
}
.questionsSection .questionsContainer .left {
}
.questionsSection .quesitonsSectionsContent {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.questionsSection .questionsContainer .center .image {
  width: 146px;
  height: 155px;
  opacity: 0.6;
}
.questionsSection .questionsContainer .right {
  width: 200px;
  height: 104px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.45;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.questionsSection .questionsContainer .right > a {
  cursor: pointer;
  text-decoration: none !important;
  color: #00a234;
}

/* Questions Section END */

.enterprise .customersSection {
  width: 100%;
  height: 400px;
  background-color: #f5f7fa;
  display: flex;
  margin-top: 100px;
}
#landingPage.enterprise .customersSection .section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* min-width: 1100px; */
  padding: 0px 30px;
}
.enterprise .customersSection .logosContainer {
  width: 50%;
  max-width: 464px;
  height: 100%;
  background-image: url('/images/landing/customers2x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* margin-right: 172px; */
}
.enterprise .customersSection .textContainer {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.enterprise .customersSection .textContainer .desc {
  font-size: 22px;
  line-height: 1.45;
  padding-left: 20px;
}
.enterprise .customersSection .textContainer .desc {
  margin: 40px 0px 0px;
  font-size: 20px;
  line-height: 1.5;
  padding-left: 0px;
}
.enterprise .customersSection .textContainer .title,
.enterprise .customersSection .textContainer .desc .bold {
  font-weight: bold;
}
.enterprise .customersSection .textContainer .desc .bold {
  margin-right: 5px;
}
.enterprise .questionsSection {
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .upgradeProSection .section {
    padding: 0px 40px !important;
  }
  .upgradeProSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .upgradeProSection .left {
    padding-left: 0px !important;
  }
  .upgradeProSection .left .perksContainer {
    margin-left: 20px;
  }
  .upgradeCard {
    width: 280px;
    padding: 80px 30px;
    margin-right: 0px;
  }

  .enterprise .customersSection {
    height: auto;
  }
  #landingPage.enterprise .customersSection .section {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .enterprise .customersSection .section .sectionTitle.mobile {
    margin-left: 0px !important;
  }
  .enterprise .customersSection .textContainer {
    margin-bottom: 42px;
    width: 100%;
    height: auto;
  }
  .enterprise .customersSection .logosContainer {
    width: 100%;
    height: 320px;
  }
  .enterprise .customersSection .textContainer .desc {
    font-size: 16px;
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .enterprise .plansSection {
    margin-bottom: 60px;
  }
  .enterprise .plansSection.mobile .plansContainer {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 520px;
    align-self: center;
  }
  .enterprise .plansSection.mobile .section .titleContainer .sectionSubTitle {
    margin-left: 0px !important;
  }
  .enterprise .plansSection.mobile .plansContainer .planContainer {
    padding: 16px !important;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .details {
    padding-top: 6px;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .planName {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    text-align: left;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .employeeCount {
    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .price {
    text-align: right;
    color: #24b646;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    /* display: flex; */
    justify-content: flex-end;
  }
  .enterprise .plansSection.mobile .planContainer .basicInfo .customPrice {
    text-align: right;
    color: #24b646;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    max-width: 100px;
  }
  .enterprise
    .plansSection.mobile
    .planContainer
    .basicInfo
    .price
    > .currency {
    font-size: 14px;
    line-height: 1.57;
    font-weight: normal;
    margin-right: 4px;
  }
  .enterprise .plansSection .plansContainer .planContainer .frequency {
    margin-top: 0px;
    font-size: 12px;
  }
  .enterprise .plansSection .plansContainer .planContainer .addOnInfo {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    width: 100%;
  }
}
@media screen and (max-width: 350px) {
  .enterprise .customersSection .logosContainer {
    height: 200px;
  }
}
