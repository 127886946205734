#landingHeader {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
#landingHeader .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 10px !important;
}
#landingHeader .left {
  display: flex;
  align-items: center;
}
#landingHeader .left > .menuItem {
  padding: 0px 20px;
}
#landingHeader .left .menuItem {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #333333;
  height: 40px;
  display: flex;
  align-items: center;
}
#landingHeader .left .menuItem:not(.logo):hover,
#landingHeader .left .solutionsDropdown:hover,
#landingHeader .right .languageDropdown:hover {
  background-color: #f5f7fa;
  transition: 0.3s all ease;
}
#landingHeader .left .menuItem.logo {
  padding: 0;
  padding-right: 20px;
}
#landingHeader .left .image,
#landingHeader.mobile .homeLogo img {
  height: 36px;
}
#landingHeader .left .solutionsDropdown {
  display: inline-flex;
  align-items: center;
  padding: 0px 20px;
}
#landingHeader .left .solutionsDropdown .item {
  padding: 0px !important;
}
#landingHeader .left .solutionsDropdown .item > a {
  color: #4a4a4a;
  text-decoration: none !important;
  font-size: 14px;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  display: block;
}
#landingHeader i.icon.chevron {
  margin-left: 8px;
  margin-right: 0;
}
#landingHeader .right {
  display: flex;
  align-items: center;
}
#landingHeader .right .languageDropdown {
  margin-right: 10px;
  padding: 0px 20px;
  height: 40px;
  display: flex;
  align-items: center;
}
#landingHeader .right .languageDropdown i.icon.chevron.down:before {
  color: #24b646;
}
#landingHeader .right .languageDropdown .text {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #24b646;
}
#landingHeader .right .signInBtn,
#landingHeader.mobile .signInBtn {
  padding: 10px 25px;
  text-align: center;
  border-radius: 18px;
  background-color: #24b646;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  border: 0;
  cursor: pointer;
}
#landingHeader .right .dashboardBtn {
  color: #24b646;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
}
#landingHeader.mobile .menuIcon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: right;
}
#landingPage {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}
#landingPage .section {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
}
#landingPage .section.tablet {
  display: none !important;
}

.section .sectionTitle {
  font-size: 44px;
  font-weight: bold;
  line-height: 1.27;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section .sectionTitle.mobile {
  display: none !important;
}
.section .sectionTitle .secondLine {
  display: flex;
  align-items: center;
}
.section .sectionTitle .secondLine .dash {
  width: 100px;
  height: 6px;
  background-color: #4a4a4a;
  margin-right: 20px;
}
.section .titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.section .titleContainer .sectionSubTitle {
  font-size: 22px;
  color: #4a4a4a;
  line-height: 1.45;
  max-width: 520px;
}

#landingFooter {
  padding: 80px 0px 100px;
}
#landingFooter .section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1280px;
  margin: 0 auto;
}
#landingFooter .productDetails .product {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
#landingFooter .productDetails .product > img {
  width: 48px;
  height: 48px;
}
#landingFooter .productDetails .product > .name {
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #24b646;
}
#landingFooter .productDetails .warmGrey {
  font-size: 14px;
  color: #9b9b9b;
  line-height: 1.57;
}
#landingFooter .links {
  display: flex;
}
#landingFooter .links .container {
  display: flex;
  flex-direction: column;
  width: 160px;
}
#landingFooter .links .container .link {
  margin-bottom: 16px;
  font-size: 14px;
  color: #9b9b9b !important;
  text-decoration: none !important;
  font-weight: 500;
  cursor: pointer;
}
#landingFooter .links .container .link:last-child {
  margin-bottom: 0px;
}
#landingFooter .links .container > .langSelector {
  color: #9b9b9b;
}
#landingFooter .links .container > .langSelector .icon {
  margin-left: 6px;
}
#landingFooter .links .container > .langSelector .item {
  color: #9b9b9b;
}
#landingFooter .socialLinks {
  display: flex;
}
#landingFooter .socialLinks > a {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
#landingFooter .socialLinks > a:last-child {
  margin-right: 0px;
}
#landingFooter .socialLinks > a > img {
  width: 100%;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  #landingPage .section,
  #landingFooter .section {
    padding: 0px 20px;
  }
  #landingHeader .section {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  #landingPage .section {
    padding: 0px 40px;
  }
  #landingHeader .section {
    padding: 20px 40px !important;
  }
  #landingFooter .section {
    padding: 0px 40px;
  }
  .section .sectionTitle {
    display: none !important;
  }
  .section .sectionTitle.mobile {
    display: flex !important;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .section .sectionTitle.mobile .dash {
    width: 80px;
    height: 6px;
    background-color: #4a4a4a;
    margin-right: 40px;
  }
  .section .titleContainer .sectionSubTitle {
    margin-left: 120px;
    font-size: 20px;
    line-height: 1.5;
  }
  .section .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .questionsSection .questionsContainer {
    padding: 60px 30px !important;
  }
  .questionsSection .questionsContainer > .left {
    max-width: 340px;
  }
  .questionsSection .questionsContainer .right {
    width: 160px !important;
  }
  .questionsSection .questionsContainer .center .image {
    width: 172px;
    height: auto;
  }
  .questionsSection .sectionTitle.mobile {
    font-size: 40px;
    margin-left: 0px;
  }

  #landingFooter {
    padding: 60px 0px 40px;
  }
  #landingFooter .socialLinks {
    margin-top: 10px;
  }
  .introSection .details {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 767px) {
  #landingPage .section,
  #landingHeader .section,
  #landingFooter .section {
    padding: 0px 30px !important;
  }
  #landingHeader .section {
    padding: 16px 30px !important;
  }
  #landingHeader.mobile .section {
    padding: 16px 20px 16px 30px !important;
  }
  #landingHeader .left .image {
    width: 22px;
  }
  #landingHeader .right .signInBtn {
    padding: 8px 16px;
  }
  .section .sectionTitle.mobile {
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-start;
  }
  .section .sectionTitle.mobile .dash {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .section .sectionTitle.mobile .title {
    font-size: 30px;
    line-height: 1.33;
  }
  .section .titleContainer {
    margin-left: 0px !important;
  }
  .section .titleContainer .sectionSubTitle {
    font-size: 18px;
    line-height: 1.56;
    margin-left: 0px;
  }

  #landingHeader .mobileHeaderMenu a {
    color: #4a4a4a;
    text-decoration: none !important;
  }
  #landingHeader .mobileHeaderMenu .item {
    padding: 12px 16px !important;
  }
  #landingHeader .mobileHeaderMenu .item .solutionsDropdown {
    padding: 0px;
  }
  #landingHeader .mobileHeaderMenu .item .solutionsDropdown .menuItem {
    font-weight: normal;
  }

  #landingFooter {
    padding: 60px 0px 20px;
    border-top: 1px solid #dedede;
  }
  #landingFooter .section {
    flex-direction: column;
  }
  #landingFooter .links {
    margin-top: 30px;
  }

  #landingFooter .firstPart {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  #landingFooter .productDetails .product > img {
    width: 36px;
    height: 36px;
  }
  #landingFooter .productDetails .product > .name {
    margin-left: 14px;
    font-size: 24px;
  }
  #landingFooter .productDetails .warmGrey {
    font-size: 12px;
  }
  #landingFooter .socialLinks {
    margin-top: 4px;
  }
  #landingFooter .socialLinks > a {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
  #landingFooter .socialLinks > a:last-child {
    margin-right: 0px;
  }

  .pro .questionsSection {
    display: none;
  }
  .questionsSection .section {
    padding: 0 !important;
  }
  .questionsSection .questionsContainer {
    flex-direction: column;
    height: 400px !important;
  }
  .questionsSection .quesitonsSectionsContent {
    flex-direction: row-reverse;
    width: 100% !important;
    max-width: 340px;
  }
}
@media screen and (max-width: 350px) {
  #landingHeader .section {
    padding: 16px !important;
  }
  #landingHeader .right .signInBtn {
    font-size: 12px;
  }
}
