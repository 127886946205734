#landingPage .meetAppSection {
  background-image: linear-gradient(to bottom, #00a234, #00a234),
    linear-gradient(
      313deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.25)
    );
  color: #fff;
  height: 500px;
}
.meetAppSection .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meetAppSection .intro {
  padding: 100px 33px 60px 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.meetAppSection .demoAndCarouselWrapper {
  display: flex;
  width: calc(50% + 207px);
}

.meetAppSection .intro .sectionTitle {
  color: #fff;
}
.meetAppSection .intro .sectionTitle .secondLine .dash {
  background-color: #fff;
}
.meetAppSection .intro .sectionSubTitle {
  max-width: 320px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 60px;
}
.meetAppSection .intro .action {
  width: 300px;
  border-radius: 25px;
  background-color: #feff73;
  text-align: center;
  color: #00a234;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.meetAppSection .appDemo {
  position: relative;
}
.meetAppSection .appDemo .appImg {
  margin-top: -140px;
  width: 414px;
  height: 640px;
  background-color: transparent;
  background: url('/images/landing/iphonecut-frame.png') center no-repeat;
  background-size: contain;
}
.meetAppSection .appDemo .video {
  position: absolute;
  top: 80px;
  left: 29px;
  width: 353px !important;
  height: 560px !important;
}
.meetAppSection .appDemo .video > iframe {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.meetAppSection .appDemo .video > div {
  background-color: transparent !important;
}
.meetAppSection .carousel {
  padding: 60px 33px;
  padding-right: 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.meetAppSection .carousel .progress > .bar {
  width: 40px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  margin-right: 16px;
  position: relative;
  transition: all 0.3s ease;
}
.meetAppSection .carousel .progress > .bar:last-child {
  margin-right: 0px;
}
.meetAppSection .carousel .progress > .bar.full {
  background-color: #feff73;
}
.meetAppSection .carousel .progress > .bar .progressing {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #feff73;
  transition: all 0.3s ease;
  animation-name: progressAnimation;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-play-state: paused;
}
.meetAppSection .carousel .slide {
  max-width: 300px;
  min-height: 240px;
}
.meetAppSection .carousel .slide .title {
  /* margin-top: 60px; */
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.45;
}
.meetAppSection .carousel .slide .subTitle {
  font-size: 18px;
  line-height: 1.56;
}
.meetAppSection .carousel .actions {
  display: flex;
}
.meetAppSection .carousel .actions .navBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 50px;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
}
.meetAppSection .carousel .actions .navBtn.disabled {
  pointer-events: none;
}
.meetAppSection .carousel .actions .navBtn > .icon {
  text-align: center !important;
}
.meetAppSection .carousel .actions .navBtn.prev {
  margin-right: 16px;
}
.meetAppSection .carousel .actions .action {
  display: none;
  width: 230px;
  border-radius: 25px;
  background-color: #feff73;
  text-align: center;
  color: #00a234;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 20px;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .meetAppSection .appDemo .appImg {
    margin-top: -140px;
    width: 414px;
    height: 640px;
    background-color: transparent;
    background: url('/images/landing/iphonecut-frame.png') center no-repeat;
    background-size: contain;
  }
  .meetAppSection .appDemo .video {
    position: absolute;
    top: 80px;
    left: 29px;
    width: 353px !important;
    height: 560px !important;
  }
  .meetAppSection .intro {
    padding-right: 0;
  }
  .meetAppSection .carousel {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  #landingPage .meetAppSection {
    background-image: none;
    color: #fff;
    height: auto;
  }
  #landingPage .meetAppSection .section {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
  }
  .meetAppSection .intro {
    width: 100%;
    max-width: 520px;
    padding: 0;
    margin: 0 auto 100px;
    margin-left: 180px;
    align-items: flex-start;
    height: 140px;
    display: none;
  }
  .meetAppSection .section .sectionTitle {
    color: #4a4a4a;
  }
  .meetAppSection .intro .sectionSubTitle {
    color: #4a4a4a;
    max-width: 520px;
    margin-bottom: 0;
  }
  .meetAppSection .intro .action {
    display: none;
  }
  .meetAppSection .demoAndCarouselWrapper {
    background-image: linear-gradient(to bottom, #00a234, #00a234),
      linear-gradient(
        313deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.25)
      );
    width: 100%;
    flex-direction: row-reverse;
    height: 480px;
    justify-content: space-between;
  }
  .meetAppSection .appDemo .appImg {
    margin-top: -62px;
    width: 354px;
    height: 542px;
  }
  .meetAppSection .appDemo .video {
    position: absolute;
    top: 59px;
    left: 43px;
    width: 267px !important;
    height: 483px !important;
  }
  .meetAppSection .carousel {
    padding-left: 60px;
  }
  .meetAppSection .carousel .actions .action {
    /* display: block; */
  }
}

@media screen and (max-width: 767px) {
  #landingPage .meetAppSection .section {
    align-items: flex-start;
  }
  .meetAppSection .section .sectionTitle.mobile {
    margin-bottom: 0px;
  }
  .meetAppSection .demoAndCarouselWrapper {
    flex-direction: column;
    height: 590px;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 0;
    background-color: #ffffff;
    background-image: none;
  }
  .meetAppSection .iphoneWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, #00a234, #00a234),
      linear-gradient(
        334deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.25)
      );
  }
  .meetAppSection .intro {
    margin: 0 0 32px;
    padding-left: 30px;
    height: auto;
    /* padding: 0px 30px; */
    display: block;
    /* max-width: 580px; */
  }
  .meetAppSection .intro .sectionSubTitle {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    display: none;
  }
  .meetAppSection .carousel {
    padding: 0;
    height: 260px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }
  .meetAppSection .carousel .progress {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .meetAppSection .appDemo .appImg {
    margin-top: -62px;
    width: 212px;
    height: 326px;
  }
  .meetAppSection .appDemo .video {
    top: -14px;
    left: 27px;
    width: 157px !important;
    height: 278px !important;
  }
  .meetAppSection .appDemo .videoAsGif {
    position: absolute;
    top: -14px;
    left: 27px;
    width: 157px !important;
    height: 278px !important;
    background-size: cover;
  }
  .meetAppSection .carousel .progress > .bar {
    background-color: rgba(36, 182, 70, 0.2);
  }
  .meetAppSection .carousel .progress > .bar.full {
    background-color: #24b646;
  }
  .meetAppSection .carousel .progress > .bar .progressing {
    background-color: #24b646;
  }
  .meetAppSection .carousel .slide {
    min-height: 200px;
  }
  .meetAppSection .carousel .slide .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .meetAppSection .carousel .slide .subTitle {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .meetAppSection .carousel .actions {
    display: none;
  }
  .meetAppSection .carousel .actions .navBtn {
    display: none;
  }
  .meetAppSection .carousel .actions .action {
    margin: 0;
    width: 260px;
    display: none;
  }
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
