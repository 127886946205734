#loadingPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingPage .loading {
  width: 100%;
  height: 100%;
}

.badgeIconTooltip.popup.visible {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #343434;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.54;
  font-size: 13px;
  padding: 12px 14px;
}
.badgeIconTooltip.popup.visible:before {
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #343434;
}
.ui.page.modals {
  display: flex !important;
}

#container_dashboard {
  color: #000000;
  font-size: 14px;
  line-height: 1.43;
}

/* Main Menu START */

#menu {
  margin: 0 auto;
  border-bottom: 0;
  padding: 0px 50px;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
#menu .item > a {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: black;
}
#menu .item > a .ui.green.label {
  margin-left: 10px;
  padding: 5px 10px;
}
#navigation .button {
  padding: 14px 15px 16px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 14px !important;
  height: 50px;
  border-radius: 0;
  /* color: #000000; */
}
#navigation .createCompanyBtn {
  border-radius: 4px;
  height: 40px;
}
#navigation .reportsDropdownTitle {
  display: flex;
  justify-content: space-between;
}
#navigation .reportsDropdownTitle .name {
  display: flex;
  align-items: center;
  width: 60px;
}
#navigation .reportsDropdownTitle .name.en {
  width: 48px;
}
#navigation .reportsDropdownTitle .name.nl {
  width: 70px;
}
#navigation .active .reportsDropdownTitle {
  color: #000000 !important;
}
#navigation .vehiclesDropdownTitle,
#navigation .tripsDropdownTitle {
  display: flex;
  align-items: center;
}
#navigation .tripsDropdownTitle .badgeCount {
  height: 19px;
  padding: 2px 10px 0;
  display: flex;
  align-items: center;
}
#navigation .active {
  background-color: #f3f3f3 !important;
  font-weight: bold !important;
  color: #000000 !important;
}
#navigation .active .dropdown.icon {
  color: #000000 !important;
  font-weight: bold !important;
}
#menu.ui.secondary.pointing.menu > .active.item {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
}
#navigation #menu > a.item,
#navigation #menu > div.item {
  align-self: auto;
  padding-left: 15px;
  padding-right: 15px;
}
#navigation #menu > div.item.profileItem {
  padding-right: 0;
}
#navigation #menu > a.item.tripsTab {
  position: relative;
  padding: 0;
}
#navigation #menu > a.item.tripsTab .badgeCount {
  margin: 0px;
  margin-left: 1em;
  height: 20px;
  padding-top: 1px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#navigation #menu > a.item.tripsTab .tooltipIcon {
  position: absolute;
  right: -4px;
}
#navigation .tripsItemContainer {
  padding: 0 14px;
  /* padding-left: 28px; */
  display: flex;
  align-items: center;
}
#navigation #menu .reportsItem,
#navigation #menu .vehiclesItem,
#navigation #menu .profileItem {
  padding: 0 !important;
  /* padding: 0 14px !important; */
}

#navigation #newReports {
  background-color: #21ba45;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 8px 0;
  margin-left: 8px;
}

/* Profile Dropdown */
#profileDropdown {
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
#profileDropdown .menu.transition.visible {
  width: 300px;
  right: 0px;
  top: 50px;
  left: auto;
  /* margin-top: -1px; */
}
#profileDropdown .profileDropdownTitle {
  display: flex;
  align-items: center;
}
#profileDropdown .profileDropdownTitle .name {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#profileDropdown .profileDropdownTitle > .userImage,
#profileDropdown .profileDropdownTitle > svg {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 6px;
}
#profileDropdown .profileDropdownTitle > span {
  font-weight: 400;
  line-height: 1.43;
}
#profileDropdown .profileDropdownTitle > span,
.ui.dropdown > .dropdown.icon {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
#profileDropdown .noPadding {
  padding: 0 !important;
  height: auto !important;
}
#profileDropdown .profileDropdownHeader {
  background-color: #f3f3f3 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 30px 10px;
  width: 100%;
  position: relative;
  z-index: 100;
}
#profileDropdown .profileDropdownHeader > .userImage,
#profileDropdown .profileDropdownHeader > svg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0;
}
#profileDropdown .userImage {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
}
#profileDropdown .profileDropdownHeader > div {
  margin: 10px 0;
  width: 100%;
  white-space: initial;
  text-align: center;
}
#profileDropdown .profileDropdownHeader > div.email {
  font-weight: 400;
}
#profileDropdown .profileDropdownHeader > span,
#profileDropdown .profileDropdownHeader > span:hover {
  font-size: 14px;
  font-weight: normal !important;
  color: #899ba7 !important;
}
#profileDropdown .item {
  display: flex;
  justify-content: center;
  height: 50px;
  transition: all 0.3s ease !important;
}
/* #profileDropdown .item.active {
  background-color: transparent !important;
} */
#profileDropdown .item > span {
  font-weight: 600;
}
#profileDropdown .item > span.badge {
  width: 22px;
  height: 20px;
  background-color: #db2828;
  text-align: center;
  line-height: 20px;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-left: 8px;
  border-radius: 4px;
}
#menu .profileItem {
  padding: 0 10px;
}
#menu .vehiclesItem span,
#menu .reportsItem span {
  font-weight: normal;
  color: #000000;
}
#menu .vehiclesItem span {
  width: 62px;
}
#menu .profileItem span {
  font-weight: bold;
}
#menu .profileItem:hover span,
#menu .reportsDropdownItem:hover span,
#menu .tripsTab:hover span,
#menu .reportsItem:hover .reportsTrigger span,
#menu .reportsItem:hover .dropdown.icon,
#menu .vehiclesItem:hover .vehiclesDropdownTitle span,
#menu .vehiclesItem:hover .dropdown.icon,
#menu .profileItem.active span,
#menu .reportsDropdownItem.active span,
#menu .profileItem:hover .dropdown.icon {
  font-weight: bold;
  color: #000000;
  transition: all 0.2s ease;
}
#menu .reportsItem {
  padding: 0 !important;
}
#reportsDropdown,
#tripsDropdown {
  width: 100%;
  height: 100%;
}
#reportsDropdown .menu.visible {
  /* margin-top: -4px; */
  min-width: 100%;
}
#tripsDropdown .menu.visible {
  min-width: 100%;
  width: 160px;
}
#reportsDropdown .menu.visible.de .name {
  width: 100px;
}
#reportsDropdown .menu.visible.nl .name {
  width: 100%;
}
#reportsDropdown .menu.visible.it .name {
  width: 125px;
}
#reportsDropdown .menu.visible.es .name {
  width: 115px;
}
#reportsDropdown .menu.visible.en .name {
  width: 85px;
}
#reportsDropdown .menu.visible.fr .name {
  width: 110px;
}
#tripsDropdown .menu.visible.de {
  width: 130px;
}
#tripsDropdown .menu.visible.nl {
  width: 130px;
}
#tripsDropdown .menu.visible.it {
  width: 140px;
}
#tripsDropdown .menu.visible.es {
  width: 140px;
}
#tripsDropdown .menu.visible.en {
  width: 110px;
}
#tripsDropdown .menu.visible.fr {
  width: 135px;
}

.ui.menu .ui.dropdown .menu > .item > span {
  font-weight: 400 !important;
  transition: all 0.2s ease;
}
.ui.menu .ui.dropdown .menu > .item:hover {
  background-color: transparent !important;
}
.ui.menu .ui.dropdown .menu > .item:hover > span {
  font-weight: bold !important;
}
.ui.menu .ui.dropdown .menu > .active.item {
  border: none;
}
.ui.menu .ui.dropdown .menu > .active.item > span {
  font-weight: bold !important;
}

.ui.dropdown .menu > .divider {
  margin: 0 auto;
  width: 95%;
  height: 2px;
}

.badgeIconTooltip {
  width: 280px;
}
.badgeIconTooltip.trips,
.badgeIconTooltip.report {
  width: 300px;
}
.badgeIconTooltip.report .approval.clickable {
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}
.tripsLeft {
  height: 24px;
  /* width: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px !important;
}
.tripsLeft span {
  vertical-align: middle;
  font-weight: 400;
}
.tripsLeft.green {
  background-color: #24b646;
}
.tripsLeft.golden {
  background-color: #fbbd08 !important;
}
.tripsLeft.red {
  background-color: #f24a4a;
}
.badgeIconTooltip .content .upgradeInfo {
  width: 150px;
  font-size: 13px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.badgeIconTooltip .content .upgradeAction {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  display: block;
}
.badgeIconTooltip .content .upgradeAction:hover {
  text-decoration: underline;
  text-decoration-color: #ffffff;
}

.reportsBadge {
  margin-left: 1em;
  padding: 0 10px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db2828;
  border-color: #db2828;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

/* Main Menu END */

#trips #status {
  font-weight: bold;
  font-size: 18px;
}
#trips #status a {
  font-weight: normal;
  font-size: 11.2px;
  color: #2185d0;
}

.downloadHeader {
  margin-right: 0 !important;
}

.appTheme {
  color: #24b626;
}
body #root button.appTheme {
  background-color: #24b626;
  color: white;
}
i.circular.icon.appTheme {
  -webkit-box-shadow: 0 0 0 0.1em #24b626 inset;
  box-shadow: 0 0 0 0.1em #24b626 inset;
}
.filterBar {
  /* max-width: 1120px; */
  margin-left: 50px;
  /* overflow-x: auto; */
  /* white-space: nowrap; */
}
.filterBar::-webkit-scrollbar {
  /* display: none; */
  height: 2px;
}
.filterBar .floatRight {
  margin-right: 0 !important;
}
.filterBarContainer {
  background-color: #f3f3f3;
  /* background-color: #f9fafb; */
  padding: 20px;
  width: 100vw;
  margin-left: -60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  /* margin-bottom: 20px; */
}
.clearFilter {
  display: inline-block;
}
#psngrWebApp .filterBar > div {
  margin-right: 20px;
  vertical-align: top;
  display: inline-block;
}
#psngrWebApp .filterBar > .group,
#psngrWebApp .filterBar > .group > * {
  display: inline-block;
  vertical-align: top;
}
#psngrWebApp .filterBar > .group > * {
  margin-right: 20px;
}

#psngrWebApp .filterBar > .group.right {
  float: right;
}
#psngrWebApp .filterBar > .group.right .statsContainer {
  margin-right: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  padding-top: 10px;
}
#psngrWebApp .filterBar .statsContainer > .stat:after {
  content: ',';
  margin-right: 4px;
}
#psngrWebApp .filterBar .statsContainer > .stat:last-child:after {
  content: '';
  margin-right: 0px;
}
.ui.basic.button.clearButton {
  background-color: #fff !important;
  height: 38px;
}

.tripsPage {
  height: 100%;
}

/* Footer START */
.footerContainer {
  background-color: #f3f3f3;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);
}
.footer {
  /*margin: auto;*/
  padding: 20px 60px;
  height: 100px;
}
.footer .left,
.footer .right {
  vertical-align: top;
  display: inline-block;
  color: #767676;
}
.footer .left .distance,
.footer .left .expenses {
  font-size: 18px;
}
.footer .left .expenses > span:nth-child(2) {
  margin-left: 6px;
}
.footer .left .titleWrapper > * {
  display: inline-block;
}
.footer .left .titleWrapper .header {
  margin-right: 12px;
}
.footer .left .subTitleWrapper > div {
  display: inline-block;
  margin-right: 20px;
}
.footer .right .tooltipIcon {
  display: inline-block;
}
.footer .right .button {
  margin-left: 20px;
}

.footer .download,
.footer .classify {
  width: 140px;
  height: 36px;
}
.footer .classify.disabled {
  pointer-events: none;
  opacity: 0.45;
}
.footer .classify .text {
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.footer .right {
  margin-top: 15px;
  float: right;
}

#listFooter {
  height: 80px;
  box-shadow: inset 0 -1px 0 0 #dedede;
  background-color: #e8f2fa;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  padding: 28px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}
#listFooter .footer {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-right: 44px;
  padding-left: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#listFooter .footer .rightSection > * {
  text-align: right;
}
#listFooter .footer .rightSection .currency {
  min-width: 114px;
  padding-right: 18px;
  padding-left: 10px;
}
#listFooter .footer .rightSection .distance {
  min-width: 100px;
  margin-right: 22px;
}
#listFooter .footer .rightSection .distance .unit {
  margin-left: 5px;
}
#listFooter .footer .rightSection .time {
  margin-right: 40px;
}
#listFooter .footer .rightSection {
  display: flex;
}
#listFooter .footer .rightSection > * {
  display: block;
}
#listFooter .footer .rightSection > *:last-child {
  margin-right: 0px;
}
#listFooter.reportsFooter .footer {
  padding-right: 0px;
}
#listFooter.reportsFooter .footer .rightSection .currency {
  padding-right: 50px;
  min-width: 140px;
}
#listFooter.reportsFooter .footer .rightSection .tripsCount {
  margin-right: 12px;
}

/* Footer END */

.ui.popup.tripDownloadPopup {
  padding: 0;
  min-width: 380px;
}

.tripDownloadPopup .topHalf {
  background-color: #f9fafb;
  padding: 30px 28px 25px 28px;
}

.tripDownloadPopup .bottomHalf {
  padding: 30px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tripDownloadPopup .bottomHalf.addHeight {
  height: 190px;
}

.tripDownloadPopup .bottomHalfReportsCheckbox {
  display: flex;
  flex-direction: column;
}
.tripDownloadPopup .bottomHalfReportsCheckbox > .checkbox {
  margin-bottom: 10px;
}

.tripDownloadPopup .bottomHalfButtonsContainer {
  display: flex;
  justify-content: space-around;
}

.tripDownloadPopup .bottomHalfButtonsContainer > .button {
  margin: 0;
}
.inputHeader {
  margin-left: -5px;
  min-width: 100px;
}

.inputHeader > input {
  border: 1px solid transparent !important;
  padding: 5px !important;
  background: transparent !important;
}

.inputHeader > input:focus {
  border: 1px solid #cce2ff !important;
}
.inputDateRangeContainer {
  background-color: #2185d0;
  height: 40px;
  display: flex;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.inputDateRangeContainer.roundEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.inputDateRangeContainer > .calendarImage {
  margin: 11px;
  margin-right: 0;
}
.inputDateRangeWhite {
  width: 100%;
  display: flex;
  align-items: center;
}
.inputDateRangeWhite > input {
  padding-right: 38px !important;
  cursor: pointer;
}
.inputDateRangeWhite > .calendarImageBlue {
  width: 16px;
  height: 16px;
  margin-left: -28px;
}
.inputDateRange > input {
  width: 190px;
}
.dateRangePicker .calendarImage {
  width: 18px;
  height: 18px;
}
/* .dateRangePicker .calendarImage.small {
  width: 18px;
  height: 18px;
} */
.dateRangePicker {
  position: relative;
}
.dateRangePicker .button {
  background-color: #2185d0;
  color: #fff;
  height: 40px;
  margin-right: 0;
}
.dateRangePicker .button:hover,
.dateRangePicker .button:active,
.dateRangePicker .button:focus {
  background-color: #2185d0 !important;
  color: #fff !important;
}
.dateRangePicker .inputDateRange > input {
  width: 210px;
  background-color: #2185d0 !important;
  border: none;
  /* color: #fff !important; */
  font-size: 12px;
  font-weight: bold;
  width: 180px;
  height: 40px;
  cursor: pointer;
  color: transparent !important;
  text-shadow: 0 0 0 #fff !important;
}
.dateRangePicker .clearDateRange {
  position: absolute;
  right: 2px;
  height: 100%;
  line-height: 42px;
  cursor: pointer;
  top: 0;
  /* padding-right: 7px; */
}
.dateRangePicker.withRange i.icon {
  color: #d8d8d8;
}
.DayPicker-Day--disabled {
  background-color: #ffffff;
}

.dropdownFilter {
  background-color: #e0e1df !important;
  border: none !important;
}
.dropdownFilter.withSelection,
.inputDateRange.withSelection > input {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.dropdownFilter .text {
  color: rgba(0, 0, 0, 0.6);
}

.addTagHeader {
  margin: 5px 0 5px 5px;
}

.addTagHeader > input {
  width: 165px;
}

.footer .classify > div {
  margin-right: 0 !important;
}
#menu .logoItem {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.psngrLogo {
  background: url(../images/psngr-logo-green.png) center no-repeat;
  background-size: contain;
  height: 30px;
  width: 22px;
}
.companyLogo {
  background-size: contain;
  height: 50px;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailInput.email {
  margin-top: 0 !important;
}
.emailInput .inputHeader {
  width: 100% !important;
}
.emailInput .valid > input {
  border-color: #21ba45 !important;
}
.emailInput .noValid > input {
  border-color: #db2828 !important;
}
.emailInput .actions,
.accountUserInfoPassword .actions {
  margin-top: 4px;
}
.emailInput .actions .action {
  cursor: pointer;
  color: #2185d0;
}
.emailInput .actions .action.confirm {
  color: #db2828;
}
.emailInput .actions .action.bold {
  font-weight: bold;
}
.emailInput .actions .save.disabled {
  color: #899ba7;
  cursor: default;
}
.emailInput .emailExists.error {
  color: #db2828;
  font-size: 12px;
}
#tripDetails .revisionDetails {
  color: #889ba7 !important;
}
#tripDetails .revisionDetails > * {
  display: inline-block;
  vertical-align: middle;
}
#tripDetails .revisionDetails img {
  width: 16px !important;
  display: inline-block;
  margin-right: 4px;
}
#tripDetails .revisionDetails .bold {
  font-weight: bold;
  margin-right: 3px;
}
#tripDetails .notesRow {
  margin-bottom: 10px;
  padding-top: 20px;
}
#notesEditor {
  position: relative;
  min-height: 64px;
  outline: none !important;
}
#notesEditor > .readOnlyContent,
#notesEditor > textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  line-height: 1.4;
  /* height: 64px; */
  min-height: 64px !important;
  max-height: 264px;
  border: 1px solid #cacbcd;
  border-radius: 8px;
  padding: 12px !important;
  visibility: visible;
}
#notesEditor > textarea {
  width: 100%;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
#notesEditor > .readOnlyContent {
  overflow: hidden;
  border: 1px solid #dedede;
  outline: none !important;
}
#notesEditor > .readOnlyContent.editable:hover {
  border: 1px solid #cacbcd;
  cursor: pointer;
}
#notesEditor > .readOnlyContent .placeholder {
  color: #aaa;
}
#notesEditor > .readOnlyContent .tag {
  font-weight: bold;
  color: #000;
}
#notesEditor > .readOnlyContent .tag.green {
  color: #24b646;
}
#notesEditor .actions-wrapper {
  position: absolute;
  right: 14px;
  bottom: -33px;
}
#notesEditor .actions-wrapper .action {
  margin: 0px;
  font-size: 0.85714286rem;
}
#notesEditor .actions-wrapper .action.discard {
  margin-right: 8px;
}
#notesEditor .dropdownContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#notesEditor .notesEditorTestArea {
  z-index: 100;
  position: relative;
}
.stepList .two.column.secondCol {
  padding-left: 0 !important;
}
.dateRange {
  position: relative;
}
.DayPicker-Caption > .ui.button {
  margin-right: 0.4em;
  height: 20px;
  padding: 2px 6px;
  min-width: 60px;
}
.removeDateRange {
  position: absolute;
  top: 12px;
  /* top: 4px; */
  right: 7px;
  background-color: grey;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 16px; */
  color: white;
  /* padding-bottom: 16px; */
}
.removeDateRange:hover {
  cursor: pointer;
}
.removeDateRangeSpan {
  margin-top: -3px;
  height: 15px;
  line-height: 14px;
  vertical-align: middle;
}

.tooltipIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s ease;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.4);
  /* box-shadow: 0 0 0 2px rgba(0,0,0, 0.4); */
}
.tooltipIcon.filled {
  background-color: #2185d0;
  border-color: #2185d0;
  box-shadow: none;
  font-weight: 600;
  color: #fff;
}
.tooltipIcon.pulse {
  animation: pulsate 1.8s infinite;
}
.tooltipIcon.pulse.filled {
  animation: none;
}
.tooltip .floatingContent .button {
  float: right;
  margin-top: 12px;
}

.limitWidth {
  max-width: 1280px;
  margin: 0 auto !important;
  /* width: 100%; */
}
#list .table th {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: none;
  height: 39px;
}
#list .dateGrouper {
  border-top: 1px solid rgba(82, 82, 82, 0.4);
  background-color: #fafafa;
  height: 60px;
  padding-bottom: 0px;
}
#list .dateGrouperContainer:first-child .dateGrouper {
  border-top: none;
}

[data-marked='true'] {
  position: relative;
}

[data-marked='true'] * {
  z-index: 1;
}

[data-marked='true']:after {
  content: '';
  height: 105%;
  width: 30px;
  position: absolute;
  right: 4.5px;
  top: -2px;
  background: #2185d0;
  border-radius: 3px;
  z-index: 0;
}

.listHeaderContainer {
  height: 80px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
}
.listHeaderContainer .listHeader {
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listHeaderContainer .listHeader .leftSection,
.listHeaderContainer .listHeader .rightSection {
  /* width: 50%; */
}
.listHeaderContainer .listHeader .rightSection {
  text-align: right;
  float: right;
}
.listHeaderContainer .listHeader .rightSection .newReport {
  height: 40px;
  margin-right: 10px;
  padding: 11px 15px 11px 49px !important;
  font-weight: 400;
}
.listHeaderContainer .listHeader .rightSection.hide {
  visibility: hidden;
}
.listHeaderContainer .listHeader .leftSection,
.listHeaderContainer .listHeader .rightSection,
.listHeaderContainer .listHeader .leftSection > *,
.listHeaderContainer .listHeader .rightSection > *,
.listHeaderContainer .listHeader .leftSection .titleSection > * {
  display: inline-block;
  vertical-align: middle;
}
.listHeader .leftSection span {
  font-weight: normal;
}
.listHeader .leftSection .listTitle {
  margin-right: 40px;
  height: 36px;
  line-height: 36px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.listHeader .leftSection .statusDropdown {
  font-size: 20px;
  height: 36px;
  line-height: 36px;
}
.tripsListContainer .listHeader .leftSection .titleSection {
  margin-right: 30px;
}
.tripsListContainer .breadcrumb .header.link {
  max-width: 260px;
}
.listHeader .leftSection .titleSection > span:first-child {
  font-size: 20px;
}
.listHeader .leftSection .titleSection .label {
  margin-left: 10px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  padding: 0px 6px;
  font-weight: bold;
}
.listHeader .actionsSection.hide {
  visibility: hidden;
}
.listHeader .actionsSection > * {
  margin-right: 10px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #fff;
}
.listHeader .leftSection .marginLeft {
  margin-left: 10px;
}
.listHeader .exportAction,
.listHeader .leftSection .assignAction,
.listHeader .leftSection .classifyAction,
.listHeader .leftSection .deleteAction,
.listHeader .leftSection .resolveTripsAction {
  padding: 11px 15px;
  display: inline-flex;
}
.listHeader .leftSection .deleteAction.isDisabled {
  opacity: 0.45 !important;
  cursor: default;
}
.listHeader .leftSection .deleteAction.isDisabled:hover {
  background-color: #fff;
}
.listHeader .leftSection .resolveTripsAction {
  background-color: #fff !important;
  border: 1px solid #dedede;
  font-weight: normal;
}
.listHeader .leftSection .resolveTripsAction .item:first-child {
  font-weight: bold !important;
}
.listHeader .leftSection .resolveTripsAction .item:last-child {
  color: #db2828 !important;
}
.listHeader .leftSection .classifyAction .dropdown.icon {
  margin-left: 14px !important;
}
.listHeader .exportAction .menu {
  padding: 8px 0;
}
.listHeader .exportAction .menu > .item {
  display: flex;
  align-items: center;
  padding: 8px 14px !important;
}
.listHeader .exportImage {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.listHeader .leftSection .classifyImage,
.listHeader .leftSection .deleteImage,
.listHeader .leftSection .mergeTripImage {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block !important;
}

.listHeader .leftSection .mergeTripImage {
  height: 7px;
  width: 17px;
  margin-bottom: 3px;
}

.listHeader .leftSection .mergeTripButton {
  color: #2185d0;
}

.listHeader .leftSection .mergeTripButton.disabled {
  cursor: default;
  opacity: 0.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: all !important;
}

.listHeader .csvImage,
.listHeader .xlsxImage,
.listHeader .pdfImage {
  width: 14px;
  height: 16px;
}
.listHeader .leftSection .actionsSection > *:last-child {
  margin-right: 0px;
}
.listHeader .leftSection .actionsSection .classifyAction .tagsList {
  border-bottom: 1px solid #dedede;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.listHeader .leftSection .actionsSection .classifyAction .addTagInput > input {
  border: none;
}
.listHeader .leftSection .statusLabel {
  font-size: 20px;
  margin-right: 6px;
  font-weight: bold;
}
.listHeader .leftSection .statusLabel.right {
  margin-right: 40px;
}
.listHeader .leftSection .statusDropdown > .text {
  color: #2185d0;
  font-weight: bold;
  font-size: 20px;
}
.listHeader .leftSection .statusDropdown .dropdown.icon {
  margin-left: 10px;
  color: #2185d0;
}
.listHeader .rightSection .filterSearchContainer {
  position: relative;
  margin-left: 0.5em;
}

.listHeader .rightSection .filterSearch > input {
  width: 240px;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-color: transparent;
  padding-right: 24px !important;
}
.listHeader .rightSection .filterSearchContainer .clearSearch {
  position: absolute;
  right: 4px;
  height: 100%;
  line-height: 40px;
  color: #899ba7;
  cursor: pointer;
  transition: all 0.3s ease;
}
.listHeader .rightSection .filterSearchContainer .clearSearch:hover {
  color: #000;
}
.listHeader .rightSection .dateRangePicker input,
.listHeader .rightSection .dateRangePicker button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.listHeader .rightSection .dateRangePicker input.roundEdge,
.listHeader .rightSection .dateRangePicker .calendarImage.roundEdge,
.listHeader .rightSection .dateRangePicker .button.roundEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.listHeader .actionsSection .submitReportBtn {
  width: 120px;
  background-color: #145a96;
  color: #fff;
}
.listHeader .actionsSection .submitReportBtn.fr,
.listHeader .actionsSection .submitReportBtn.it,
.listHeader .actionsSection .submitReportBtn.de {
  width: 140px;
}
.listHeader .actionsSection .submitReportBtn.isDisabled,
.listHeader .actionsSection .approveAllBtn.isDisabled,
.listHeader .actionsSection .flagBtn.isDisabled {
  opacity: 0.4;
  background-color: #767676;
  cursor: default;
}
.listHeader .actionsSection .submitReportBtn.halfDisabled {
  opacity: 0.4;
  background-color: #767676;
  transition: 0.3s all ease;
}
.listHeader .actionsSection .submitReportBtn.halfDisabled:hover {
  opacity: 0.6;
  background-color: #767676;
}
.listHeader .editReportBtn {
  width: 120px;
  padding: 0;
  height: 36px;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.listHeader .editReportBtn.it,
.listHeader .editReportBtn.fr {
  width: 160px;
}
.listHeader .editReportBtn.es {
  width: 140px;
}
.listHeader .editReportBtn .editIcon {
  width: 13px;
  height: 13px;
  margin-right: 8px;
}
.listHeader .actionsSection .resubmitReportBtn {
  background-color: #145a96;
  color: #fff;
}
.listHeader .actionsSection .resubmitReportBtn.isDisabled {
  background-color: #767676;
  opacity: 0.4;
  font-weight: 400;
}

.reportTripsListContainer .listHeader .filterSearch > input {
  border-radius: 4px;
  border-right-color: rgba(34, 36, 38, 0.15);
}
.reportRulesListContainer .listHeader .leftSection .nameHeader {
  display: block;
  font-size: 20px;
  margin-bottom: 4px;
}
.reportRulesListContainer .listHeader .leftSection .subText {
  display: block;
  font-size: 14px;
  line-height: 1.29;
  color: #899ba7;
}
.reportRulesListContainer .breadcrumb .header {
  max-width: 300px;
}

.reportTripsListContainer .listHeader .approveAllBtn {
  background-color: #007d02;
  color: #fff;
}
.reportTripsListContainer .listHeader .flagBtn {
  background-color: #db2828;
  color: #fff;
}

.searchFliterTooltip {
  width: 260px;
}
.searchFliterTooltip .list .item {
  margin: 3px 0;
}
.searchFliterTooltip .list .item span {
  display: inline-block;
  vertical-align: middle;
}
.searchFliterTooltip .grey {
  color: #899ba7;
  margin: 0 3px;
}
.searchFliterTooltip .grey.noMargin {
  color: #899ba7;
  margin: 0;
}
.classifyTrigger,
.downloadTrigger {
  display: flex;
}
.classifyAction .loaderContainer,
.downloadTrigger .loaderContainer {
  position: relative;
  width: 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.newTripBtnWrapper {
  margin-right: 15px;
}

.newTripBtnWrapper.disabled button {
  opacity: 0.45;
  cursor: default;
}

/* tablet */
@media only screen and (max-width: 991px) {
  #menu {
    padding: 0 25px;
    /* padding: 0 20px; */
  }
  #psngrWebApp .filterBar {
    margin: 0;
  }
  #psngrWebApp .filterBar > .group {
    margin-bottom: 12px;
  }
  #psngrWebApp .filterBar > .group:last-child {
    margin-bottom: 0px;
  }
  .filterBarContainer {
    margin-left: -20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

/* mobile */
@media only screen and (max-width: 767px) {
  #menu {
    padding: 0;
  }
  /* trips list */
  .tripsListContainer .footer {
    padding: 20px;
    height: 160px;
  }
  .tripsListContainer .footer > .right {
    margin-top: 0px;
  }
  .tripsListContainer .footer > .right > * {
    display: block;
    margin: 0px 0px 6px 0px;
  }
  .tripsListContainer .footer > .right > .button.delete {
    float: right;
  }
  .tripsListContainer .footer > .right > *:last-child {
    margin-bottom: 0px;
  }
  .listHeaderContainer .listHeader {
    padding: 0 20px;
  }
  /* trips list */
  #list .table th {
    height: 21px;
  }
  #table-body .dateGrouperContainer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #table-body .dateGrouper {
    width: 100% !important;
    display: flex !important;
    align-items: center;
  }
}

@media only screen and (max-width: 450px) {
  .ui.input.inputDateRange,
  .dropdown.button.withSelection.tags {
    margin-bottom: 12px;
  }
  .filterBarContainer {
    margin-left: -10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}

@keyframes fadeInEntry {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
